import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./constants";

export const notificationApi = createApi({
  reducerPath: "notification/api",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const { token } = getState().users;
      headers.set("Content-Type", "application/json");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getNotifications: build.query({
      query: ({pageNumber}) => ({
        url: `api/notifications/get-unread-user-notifications-disp?pageNumber=${pageNumber}`,
      }),
    }),
    getNotificationsCount: build.query({
      query: () => ({
        url: `api/notifications/get-unread-user-notifications-disp-count`,
      }),
    }),
    markNotificationAsRead: build.mutation({
      query: (data) => ({
        url: "api/notifications/mark-notification-as-read",
        method: "PUT",
        body: data,
      }),
    }),
  }),
});

export const { useMarkNotificationAsReadMutation,useLazyGetNotificationsCountQuery, useLazyGetNotificationsQuery } =
  notificationApi;
