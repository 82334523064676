import React, { useMemo,useState } from "react";
import { Tooltip, Typography,Box } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { dateFormat, dateFormatWithTimeWithoutA } from "../../../constants";
import { SelectMultipleChipDeletableWithSearchField } from "../../components/SelectMultipleChipDeletableWithSearchField";
import { useGetFacFireCompSelectElementsQuery } from "../../../middleware/facilitiesApi";

export const ControllerStatusCell = ({ item }) => {

  const { t } = useTranslation();
  const status = useMemo(
    () => (item ? "connectedController" : "disconnectedController"),
    [item]
  );

  return (
    <Tooltip title={t(`label:controllerStatusTooltips:${status}`)}>
      <CircleIcon sx={{ color: `var(--${status})` }} />
    </Tooltip>
  );
};
export const SimpleTextCell = ({ item, type }) => {
  const content = type === "date" ? dayjs(item).format(dateFormat) : type === "dateTime" ? dayjs(item).format(dateFormatWithTimeWithoutA) : item;

  return <Typography>{content}</Typography>;
};
export const ClickableNameCell = ({ item }) => {
  const { name, action } = item || {};
  console.log("item", item)
  return (
    <Typography
      className="table-row-cell-link"
      onClick={action ? action : () => { }}
    >
      {name || ""}
    </Typography>);
};
export const SimpleHeader = ({ name }) => (
  <Box className="table-head-up_level">
    <Typography className="standart">{name || ""}</Typography>
  </Box>
);
export const ObjectsSelect = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const { data: facFireCompSelect = [] } =
    useGetFacFireCompSelectElementsQuery(searchValue);

  return <SelectMultipleChipDeletableWithSearchField
    setSearchValue={setSearchValue}
    isIds={true}
    searchValue={searchValue} options={facFireCompSelect} {...props} />;
};
