import React, { useEffect, useMemo, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm, useWatch } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import "./styles/style.css";
import { eventsOptions, bgColors } from "./options";
import {
  allColumns,
  DEFAULT_PARAMS,
  formFields,
  getConfigFormModalSelect,
} from "./form-config";
import { useAction } from "../../../hooks/actions";
import { getColumns } from "./columns";
import FormItem from "../../components/FormItem";
import SelectSimple from "../../components/SelectSimple";
import { useAppSelector } from "../../../hooks/redux";
import { useLazyGetAllMagazineEventsQuery } from "../../../middleware/magazineApi";
import { getBgColor, getBorderColor } from "./helpers";
import TableBasic from "../../components/TableBasic";
import { ModalSelectColumnsServer } from "../../components/Modals/ModalSelectColumnsServer";
import { useLazyPrintEventLogsListQuery } from "../../../middleware/logsApi";
import PrintIcon from '@mui/icons-material/Print';
import CircularProgress from '@mui/material/CircularProgress';
import { useGetUserGridSettingQuery, useLazyGetUserGridSettingQuery } from "../../../middleware/usersApi";
export const MagazineEvents = () => {
  const { t } = useTranslation();
  const [getParams, setSearchParams] = useSearchParams();
  const ShowOnlyFireMessagesPerActiveSession = getParams.has("ShowOnlyFireMessagesPerActiveSession") ? getParams.get("ShowOnlyFireMessagesPerActiveSession") : getParams.get("status") ? getParams.get("status") =="fire_messages_amount" ? true : false :  "";
  const  facilityName  =  getParams.has("facilityName") ? getParams.get("facilityName") : "";

  const { control, setValue, getValues } = useForm({
    mode: "onChange",
    defaultValues: {
      [formFields.searchParams]: DEFAULT_PARAMS,
    },
  });

  const params = useWatch({
    control,
    name: formFields.searchParams,
  });

  const { allMagazineEvents } = useAppSelector((state) => state.magazine);
  const { setIsErrorRequest } = useAction();
  const [printloading, setPrintLoading] = useState(false)
  const [facilitySearch, setFacilitySearch] = useState("");
  const [messageClassSearch, setMessageClassSearch] = useState("");
  const [jobName, setJobName] = useState("");
  const [equipName, setequipName] = useState("");
  const [searchController, setsearchController] = useState("");
  const [searchAddress, setsearchAddress] = useState("");
  const [roomPlume, setroomPlume] = useState("");
  const [executiveUser, setexecutiveUser] = useState("");
  const [responsibleUser, setresponsibleUser] = useState("");
  const [triggerCause, settriggerCause] = useState("");
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [pageNumber, changePageNumber] = useState(0);
  const [openEquipDialog, setopenEquipDialog] = useState(false);
  const [selectedEquipId, setSelectedEquipId] = useState(null);
  const [openTaskDialog, setOpenTaskDialog] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    data: gridcolumns,
    error: errorgridColumns,
    isLoading: isLoadingerrorColumns,
  } = useGetUserGridSettingQuery({ gridSettingName: "EventLogJournal" });

  const [getUserGrid, { error: errorgrid, refetch: refetchUserGrid /* isLoading */ }] =
    useLazyGetUserGridSettingQuery();
  const [getAllMagazineEvents, { error, isLoading }] =
    useLazyGetAllMagazineEventsQuery();
  const [printLogsList, { errorPrint, isLoadingPrint }] =
    useLazyPrintEventLogsListQuery();

  const handleOpenTaskDialogState = (id) => {
    setOpenTaskDialog(true);
    setSelectedTaskId(id);
  };

  const rows = useMemo(() => {
    const { data } = allMagazineEvents || {};

    return data?.length
      ? data.map(
        ({ protectionObjectAddress, ...item
        }) => {

          return {
            address: `${protectionObjectAddress?.city}, ${protectionObjectAddress?.street} ${protectionObjectAddress?.building}`,
            ...item
          }
        }
      )
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMagazineEvents]);
  const columnsFromStorage = (() => {
    const storageData =
      gridcolumns?.columnSettings || [];
    return storageData?.reduce(
      (acc, { name, visible }) => ({ ...acc, [name]: visible }),
      {}
    );
  })();
  const handleOpenEquipment = (id) => {
    setopenEquipDialog(true);
    setSelectedEquipId(id);
  };

  const columns = useMemo(
    () =>
      getColumns({
        control,
        searchAddress,
        setsearchAddress,
        messageClassSearch,
        setMessageClassSearch,
        searchController,
        setsearchController,
        roomPlume,
        setroomPlume,
        triggerCause,
        settriggerCause,
        executiveUser,
        setexecutiveUser,
        responsibleUser,
        setresponsibleUser,
        columnsFromStorage,
        handleOpenEquipment,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsFromStorage]
  );

  const recordsCount = useMemo(
    () => allMagazineEvents && allMagazineEvents?.recordsCount,
    [allMagazineEvents]
  );

  const handleOpenSelectColumnsModal = () => {
    setOpenSelectModal(true);
  };

  useEffect(() => {
    setValue(
      `${formFields.searchParams}.${formFields.facilitySearch}`,
      facilitySearch
    );
  }, [facilitySearch, setValue]);
  useEffect(() => {
    setValue(
      `${formFields.searchParams}.${formFields.FacilityId}`,
      facilityName
    );
  }, [facilityName, setValue]);
  useEffect(() => {
    const messageTypeVal = ShowOnlyFireMessagesPerActiveSession ==true ? 1 : ShowOnlyFireMessagesPerActiveSession =="" ? "все" : 2
    setValue(
      `${formFields.searchParams}.ShowOnlyFireMessagesPerActiveSession`,
      messageTypeVal
    );
  }, [ShowOnlyFireMessagesPerActiveSession, setValue]);
  
  useEffect(() => {
    setValue(
      `${formFields.searchParams}.RoomPlume`,
      roomPlume
    );
  }, [roomPlume, setValue]);
  useEffect(() => {
    setValue(
      `${formFields.searchParams}.TriggerCause`,
      triggerCause
    );
  }, [triggerCause, setValue]);
  useEffect(() => {
    setValue(
      `${formFields.searchParams}.MessageClass`,
      messageClassSearch
    );
  }, [messageClassSearch, setValue]);
  useEffect(() => {
    setValue(
      `${formFields.searchParams}.ExecutiveUser`,
      executiveUser
    );
  }, [executiveUser, setValue]);
  useEffect(() => {
    setValue(
      `${formFields.searchParams}.ResponsibleCustomerUser`,
      responsibleUser
    );
  }, [responsibleUser, setValue]);
  useEffect(() => {
    setValue(
      `${formFields.searchParams}.ResponsibleCustomerUser`,
      responsibleUser
    );
  }, [responsibleUser, setValue]);
  useEffect(() => {
    setValue(
      `${formFields.searchParams}.ControlConsoleName`,
      searchController
    );
  }, [searchController, setValue]);

  useEffect(() => {
    const city = searchAddress?.city ? `&AddressFilter.City=${searchAddress?.city}` : ""
    const street = searchAddress?.street ? `&AddressFilter.Street=${searchAddress?.street}` : ""
    const building = searchAddress?.building ? `&AddressFilter.Building=${searchAddress?.building}` : ""
    const address = city + street + building
    const createdDate = getValues(
      `${formFields.searchParams}.ReactionDateRange`
    );
    const facilId = getValues(`${formFields.searchParams}.${formFields.FacilityId}`)
   
    const facility = (facilId == "все" || !facilId) ? { FacilityId: "" } : { FacilityId: facilId }
    const ShowOnly = getValues(`${formFields.searchParams}.ShowOnlyFireMessagesPerActiveSession`)
    console.log("ShowOnly",ShowOnly)
    const MessageFilter = ShowOnly =="все"  || ShowOnly =="" ? {ShowOnlyFireMessagesPerActiveSession: ""} : ShowOnly ==1 ? {ShowOnlyFireMessagesPerActiveSession: true} :{ShowOnlyFireMessagesPerActiveSession: false}
    delete params.ReactionDateRange
    delete params.FacilityId
    delete params.ShowOnlyFireMessagesPerActiveSession
    let dd = {}
    if (createdDate) {
      if (createdDate.MaxValue != "")
        dd["ReactionDateRange.MaxValue"] = createdDate.MaxValue
      if (createdDate.MinValue != "")
        dd["ReactionDateRange.MinValue"] = createdDate.MinValue
    }
    getAllMagazineEvents({
      ...dd,
      ...facility,
      ...MessageFilter,
      ...params,
      address: address,
      pageNumber: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, searchAddress]);

  useEffect(() => {
    const city = searchAddress?.city ? `&AddressFilter.City=${searchAddress?.city}` : ""
    const street = searchAddress?.street ? `&AddressFilter.Street=${searchAddress?.street}` : ""
    const building = searchAddress?.building ? `&AddressFilter.Building=${searchAddress?.building}` : ""
    const address = city + street + building
    const createdDate = getValues(
      `${formFields.searchParams}.ReactionDateRange`
    );
    const facilId = getValues(`${formFields.searchParams}.${formFields.FacilityId}`)
    const ShowOnly = getValues(`${formFields.searchParams}.ShowOnlyFireMessagesPerActiveSession`)
    const MessageFilter = ShowOnly =="все"  || ShowOnly =="" ? {ShowOnlyFireMessagesPerActiveSession: ""} : ShowOnly ==1 ? {ShowOnlyFireMessagesPerActiveSession: true} :{ShowOnlyFireMessagesPerActiveSession: false}
    const facility = (facilId == "все" || !facilId) ? { FacilityId: "" } : { FacilityId: facilId }
    delete params.ReactionDateRange
    delete params.FacilityId
    delete params.ShowOnlyFireMessagesPerActiveSession
    let dd = {}
    if (createdDate) {
      if (createdDate.MaxValue != "")
        dd["ReactionDateRange.MaxValue"] = createdDate.MaxValue
      if (createdDate.MinValue != "")
        dd["ReactionDateRange.MinValue"] = createdDate.MinValue
    }
    getAllMagazineEvents({
      ...dd,
      ...facility,
      ...MessageFilter,
      ...params,
      address: address,
      pageNumber,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);
  const base64toPDF = (name, content) => {
    var bufferArray = base64ToArrayBuffer(content);
    var blobStore = new Blob([bufferArray], { type: "application/pdf" });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blobStore);
      return;
    }
    var data = window.URL.createObjectURL(blobStore);
    var link = document.createElement('a');
    document.body.appendChild(link);
    link.href = data;
    link.download = name;
    link.click();
    window.URL.revokeObjectURL(data);
    link.remove();
    setLoading(false)
  }
  const printBase64 = (content) => {
    var bufferArray = base64ToArrayBuffer(content);
    var blob = new Blob([bufferArray], { type: 'application/pdf' }); //this make the magic
    var blobURL = URL.createObjectURL(blob);

    let iframe = document.createElement('iframe'); //load content in an iframe to print later
    document.body.appendChild(iframe);

    iframe.style.display = 'none';
    iframe.src = blobURL;
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
    setPrintLoading(false)
  }

  const base64ToArrayBuffer = (data) => {
    var bString = window.atob(data);
    var bLength = bString.length;
    var bytes = new Uint8Array(bLength);
    for (var i = 0; i < bLength; i++) {
      var ascii = bString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };
  const printLogs = (isPrint) => {
    if (isPrint)
      setPrintLoading(true);
    else
      setLoading(true);
    const city = searchAddress?.city ? `&AddressFilter.City=${searchAddress?.city}` : ""
    const street = searchAddress?.street ? `&AddressFilter.Street=${searchAddress?.street}` : ""
    const building = searchAddress?.building ? `&AddressFilter.Building=${searchAddress?.building}` : ""
    const address = city + street + building
    const createdDate = getValues(
      `${formFields.searchParams}.ReactionDateRange`
    );
    const facilId = getValues(`${formFields.searchParams}.${formFields.FacilityId}`)

    const facility = (facilId == "все" || !facilId) ? { FacilityId: "" } : { FacilityId: facilId }
    const ShowOnly = getValues(`${formFields.searchParams}.ShowOnlyFireMessagesPerActiveSession`)
    const MessageFilter = ShowOnly =="все" || ShowOnly =="" ? {ShowOnlyFireMessagesPerActiveSession: ""} : ShowOnly ==1 ? {ShowOnlyFireMessagesPerActiveSession: true} :{ShowOnlyFireMessagesPerActiveSession: false}
    delete params.ReactionDateRange
    delete params.FacilityId
    delete params.ShowOnlyFireMessagesPerActiveSession
    let dd = {}
    if (createdDate) {
      if (createdDate.MaxValue != "")
        dd["ReactionDateRange.MaxValue"] = createdDate.MaxValue
      if (createdDate.MinValue != "")
        dd["ReactionDateRange.MinValue"] = createdDate.MinValue
    }
    printLogsList({
      ...dd,
      ...facility,
      ...MessageFilter,
      ...params,
      address: address,
      pageNumber,
    }).then((data) => {
      const res = data.data
      if (isPrint)
        printBase64(data.data.content)
      else
        base64toPDF(data.data.name, data.data.content)
    });
  }
  useEffect(() => {
    setIsErrorRequest(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  const eventsOptions = [
    {
      value: "все",
      name: t(`options_name:all_fire_messages`),
    },
    {
      value: 1,
      name: t(`options_name:fire_messages_amount`),
    },
    {
      value: 2,
      name: t(`options_name:false_fire_messages_amount`),
    },

  ];
  return (
    <Box className="template_page magazine_events">
      <Box className="template_page-header">
        <Typography className="template_page-header-title">
          {t("title_page:magazines_events")}
        </Typography>
        <Box sx={{ display: "flex" }}>
          {printloading ?
            <Box className="full_screen_icon-wrapper">
              <CircularProgress className="full_screen_icon" sx={{
                width: "30px",
                height: "30px",
                marginRight: "20px",
                marginTop: "5px"
              }}
              />
            </Box>
            :
            <Box className="full_screen_icon-wrapper">
              <PrintIcon
                className="full_screen_icon"
                onClick={() => printLogs(true)}
                sx={{
                  width: "30px",
                  height: "30px",
                  marginRight: "20px",
                  marginTop: "5px",
                  border: "0px !important"
                }}
              />
            </Box>
          }
          <Button
            className="btn btn-outlined-primary magazine_events-select_container-btn"
            variant="outlined"
            onClick={() => printLogs(false)}
            disabled={loading}
            sx={{ height: "49px", marginRight: "16.8px" }}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : t("button:create_act_pdf")}
          </Button>
          <Button
            className="btn btn-outlined-primary magazine_events-select_container-btn"
            variant="outlined"
            onClick={handleOpenSelectColumnsModal}
            sx={{ height: "49px" }}
          >
            {t("button:customize_fields_to_display")}
          </Button>
        </Box>
      </Box>
      <form>
         <Box className="magazine_events-select_container">
          <FormItem
            control={control}
            name={`${formFields.searchParams}.ShowOnlyFireMessagesPerActiveSession`}
            label={t("label:events")}
            type={"select-text"}
            defaultValue={"все"}
            options={eventsOptions}
            input={(props) => (
              <Box className="medium_container">
                <SelectSimple {...props} />
              </Box>
            )}
          />
        </Box>
            
        <TableBasic
          columns={columns}
          rows={rows}
          handleClickCell={() => { }}
          withSearchField={false}
          isLoading={isLoading}
          pageNumber={pageNumber}
          changePageNumber={changePageNumber}
          count={recordsCount}
          getCustomBgColorRow={getBgColor(bgColors)}
          getCustomBorderRow={getBorderColor()}
          minHeight={"49vh"}
          maxHeight={"70vh"}
        />
      </form>

      <ModalSelectColumnsServer
        open={openSelectModal}
        setOpen={setOpenSelectModal}
        data={columnsFromStorage}
        refetch={() => getUserGrid({ gridSettingName: "EventLogJournal" })}
        nameStorage={"EventLogJournal"}
        config={getConfigFormModalSelect(gridcolumns?.columnSettings || [])}
      />

    </Box>
  );
};
