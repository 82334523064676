import React from "react";
import { Placemark } from "@pbe/react-yandex-maps";
import { useNavigate } from "react-router-dom";
import { useAction } from "../../../hooks/actions";

import "./styles/style.css";
import { PATHS, globalFields } from "../../../constants";

const CustomPlacemark = ({ id, geometry, onClick, name, jobChart, linkChart,onClickPopUp,onTaskStateClick }) => {
  const navigate = useNavigate();
  const { setSortParamsFacilityName } = useAction();
  const getPath = (name) => globalFields[name];

  window.handleNavigate = (props, id) => {
    onTaskStateClick(id,props)
  };

  window.goToObject = (id) => {
    onClickPopUp(id)
  }; 

  return (
    <Placemark
      key={id}
      geometry={geometry}
      onClick={onClick}
      options={{
        iconLayout: "default#image",
        iconImageHref: linkChart,
        iconImageSize: [40, 40],
      }}
      properties={{
        balloonContentHeader: `${name}`,
        balloonContentBody: `
          <div class="balloon_content">
            <ul class="balloon_content-list">
              <li class="balloon_content-list-item">
                <p onclick="window.handleNavigate('fire_messages_amount', '${id}')" class="link-black">
                  <span class="link_point" style="background-color: #fb0104;"></span>
                  Количество сообщений 'пожар' за смену: ${jobChart?.fireMessagesAmount || 0}
                </p>
              </li>
              <li class="balloon_content-list-item">
                <p onclick="window.handleNavigate('false_fire_messages_amount', '${id}')" class="link-black">
                  <span class="link_point" style="background-color: #ff6800;"></span>
                  Количество ложных сообщений 'пожар' за смену: ${jobChart?.falseFireMessagesAmount || 0}
                </p>
              </li>
            </ul>
          </div>
        `,
        balloonContentFooter: `
          <div class="balloon_footer">
            <p
              class="balloon_footer-text"
              onclick="window.goToObject('${id}')"
            > 
              Подробнее...
            </p>          
          </div>
        `,
        clusterCaption: `<strong>${name}</strong>`, 
      }}

      modules={["geoObject.addon.balloon", "geoObject.addon.hint"]}
      defaultState={{ balloon: { open: true } }}
      instanceRef={(ref) => ref && ref.balloon && ref.balloon.close()}
    />
  );
};

export default CustomPlacemark;
