import React, { useEffect, useState } from "react";
import { Button, Badge } from "@mui/material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { NotificationItem } from "./NotificationItem";
import { useTranslation } from "react-i18next";
import {
  useMarkNotificationAsReadMutation,
  useLazyGetNotificationsQuery,
  useLazyGetNotificationsCountQuery
} from "../../../middleware/notificationApi";
import { useLazyGetSystemOverallAnalyticsQuery } from "../../../middleware/analyticsApi";
import { useLazyGetAllFacilitiesQuery } from "../../../middleware/facilitiesApi";
import { useLazyGetAllMagazineEventsQuery } from "../../../middleware/magazineApi";
import { useAction } from "../../../hooks/actions";
import "./style.css";
import { PushEvent } from "./PushEvent";

export const NotificationPopUp = () => {
  let interval;
  const [notifications, setNotifications] = useState([])
  const [notificationsCount, setnotificationsCount] = useState(0)
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const { t } = useTranslation();

  const { setIsErrorSubmit } = useAction();

  const [getFacilitiesTable, { error, isLoading }] =
  useLazyGetAllFacilitiesQuery();
  const [getAllMagazineEvents, { errormaga, isLoadingmaga }] =
  useLazyGetAllMagazineEventsQuery();
  const [getAnalytics, { error:ratingError, isLoading:isload }] =
  useLazyGetSystemOverallAnalyticsQuery();
  const [getNotification, { error: errorNotif, refetch: refetchNotifications /* isLoading */ }] =
    useLazyGetNotificationsQuery();
  const [getNotificationcount, { error: errorNotifcount }] =
    useLazyGetNotificationsCountQuery();

  const intervalNotifCount = () => {
    interval = setInterval(() => {
      getNotificationcount().then((data) => setnotificationsCount(data.data));
    }, 15000); // Вызов функции callApi каждые 30 секунд

    return () => clearInterval(interval);
  }
  useEffect(() => {
    intervalNotifCount()
  }, []);
  const fetchData = async () => {

    try {
      const response = await getNotification({
        pageNumber: 0,
      });
      console.log("response", response.data)
      setNotifications(response?.data.data)
      clearInterval(interval)
      setOpen(true)
    } catch (error) {
      console.log("error");
    }
  };
  useEffect(() => {
    if (notificationsCount > 0)
      fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsCount]);

  useEffect(() => {
    setIsErrorSubmit(errorNotif);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorNotif]);

  const checkMessages = () => {
    getNotificationcount().then((data) => {
      const countMessage = data.data
      setOpen(false);
      if(countMessage>0){
        fetchData();
      }else{
        getAnalytics()
        getFacilitiesTable({ pageNumber: 0, IntegrationNumber: "", Name: "", ShowObjectInFireAlarmsPerActiveSession: "", Address: "" });
        getAllMagazineEvents({
          pageNumber: 0,
        })
        intervalNotifCount()
      }
    });
  }

  console.log("notifications", notifications)
  return (
    <PushEvent
      open={open}
      onClose={setOpen}
      funtinterval = {checkMessages}
      notifications={notifications}

    />
  );
};
