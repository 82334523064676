import React,{useState} from "react";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { LocalizationProvider, ruRU } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/ru";
import { Typography } from "@mui/material";
import TextField from '@mui/material/TextField';
import { dateFormatWithTimeWithoutA, dateFormatWithTimeWithoutAForSubmit } from "../../../constants";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

const DatePickerInbuild = ({ placeholder, onChange, value, bgcolor, className }) => {
  const [inputText, setInputText] = useState('');
  const style = {
    width: "100%",
    backgroundColor: bgcolor ? bgcolor : "inherit",
    height: "24px",
    "& .MuiInputLabel-root": {
      color: "var(--black)",
      fontFamily: "var(--IBM)",
      fontSize: "var(--standart)",
      fontWeight: "500",
      top: className ? "-11px" : "-16px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
      "& .MuiOutlinedInput": className ? className : {
        color: "var(--black)",
        fontFamily: "var(--IBM)",
        fontSize: "var(--standart)",
        padding: 0,
        fontWeight: "500",
        paddingTop: "0",
        paddingBottom: "0",
      },
    },
    "& .MuiOutlinedInput-input": { padding: "0 8px" },
    "& .MuiFormLabel-filled.MuiInputLabel-shrink": {
      display: "none",
      position: "absolute",
      transform: "translate(14px, 16px) scale(1)",
    },
    "& .MuiInputBase-root": {
      paddingRight: "0",
      height: className ? "24px" : "inherit",
    },
    "& .MuiButtonBase-root": {
      padding: "0",
      marginRight: "2px",
      "& svg": {
        width: "20px",
      }
    }
  };

  const handleChange = (e) => {
    if (!e) {
      onChange(null)
      return
    }
    const date = e.$d
    const newValue = isNaN(Date.parse(date))
      ? ""
      : dayjs(date).format(dateFormatWithTimeWithoutAForSubmit);
    onChange(newValue);
  }
  const handleInputTextChange = (event) => {
    console.log("eeveve",event.target.value)
    setInputText(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleChange(event);
    }
  };

  const slots = value ? {
    inputAdornment: () => (
      <IconButton
        type="button"
        aria-label="clean search"
        onClick={() => {
          onChange("");
        }}
        sx={{ padding: "0" }}
      >
        <ClearIcon sx={{ fontSize: 22 }} />
      </IconButton>
    )
  } : {};

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale="ru"
      localeText={
        ruRU.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >

      <DesktopDateTimePicker
        className={`date-picker ${className}`}
        sx={style}
        label={placeholder}
        ampm={false}
        format={dateFormatWithTimeWithoutA}
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        }}
        fullWidth
        value={value ? dayjs(value) : null}
        disableFuture
        onAccept={handleChange}
        //onChange={(e) => console.log('hey', dayjs(e))}
        KeyboardIconProps={{
          onKeyDown: () => console.log('hey')
        }}
        renderInput={(params) => <TextField {...params} onKeyPress={handleKeyPress} value={inputText} onChange={handleInputTextChange} />}

        slots={slots}
      />

    </LocalizationProvider>
  );
};

export default DatePickerInbuild;
