import { ClickableNameCell } from "./Components";
import { SimpleHeader } from "../Controllers/Components";
import { Box } from "@mui/material";
import { formFieldsTable as formFields } from "./form-config";
import { rolesOptions, typeOptions, activeOptions, verifiedOptions } from "./options";
import SelectSimpleInbuilt from "../../components/SelectSimpleInbuilt";
import {
  BlockAvatar,
  ClickableActionNameCell,
  SimpleTextCell,
  LinkCell
} from "./Components";
import { t } from "i18next";
import FormItem from "../../components/FormItem";
import { Verified } from "@mui/icons-material";
import { SearchInbuilt } from "../../components/SearchInbuilt";
import { SelectSimpleObject } from "../MagazineEvents/Components"

export const getColumns = ({
  control,
  columnsFromStorage,
  phone,
  setphone,
  searchName,
  setsearchName,
  login,
  setLogin,
  email,
  setEmail,

  rows
}) =>
  [
    {
      id: formFields.avatarLink,
      numeric: false,
      enableSorting: false,
      label: "",
      styleTableCell: {
        verticalAlign: "top",
      },
      clickable: false,
      isVisible: columnsFromStorage[formFields.avatarLink],
      RowCell: ({ row }) => <BlockAvatar data={row[formFields.avatarLink]} />,
    },
    {
      id: formFields.employeeFullName,
      numeric: false,
      enableSorting: false,
      label: t("label:FIO_employee"),
      styleTableCell: {
        verticalAlign: "top",
      },
      size: "150px",
      isVisible: columnsFromStorage[formFields.employeeFullName],
      RowCell: (props) => <ClickableActionNameCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "120px" }}>
          <SimpleHeader name={t("label:FIO_employee")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.employeeFullName}`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={searchName}
                changeSearchValue={setsearchName}
                {...props}
              />
            )}
          />
        </Box>

      ),
    },
   /* {
      id: formFields.isActive,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:isActive"),
      styleTableCell: {
        verticalAlign: "top",
      },
      size: "100px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.isActive],
      RowCell: (props) => <SimpleActiveTextCell {...props} />,
      HeaderCell: () => (
        <Box className="input-wrapper" sx={{ width: "140px" }}>
          <SimpleHeader name={t("tableHeader:isActive")} />
          <Box sx={{ padding: "12px 0" }}>
            <SelectSimpleInbuilt
              className="select_in_built small-light"
              value={activeValue}
              onChange={changeactiveValue}
              sx={{ padding: "12px 0" }}
              options={activeOptions}
              bgcolor={"var(--bgLightGrey)"}
            />
          </Box>
        </Box>
      ),
    }, */
    {
      id: formFields.employeeLogin,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:employeeLogin"),
      styleTableCell: {
        verticalAlign: "top",
      },
      size: "150px",
      isVisible: (columnsFromStorage[formFields.employeeLogin] && !rows?.hideEmployeeLogin),
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "120px" }}>
          <SimpleHeader name={t("tableHeader:employeeLogin")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.employeeLogin}`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={login}
                changeSearchValue={setLogin}
                {...props}
              />
            )}
          />
        </Box>

      ),
    },
    {
      id: "phoneNumber",
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:object_phone"),
      styleTableCell: {
        verticalAlign: "top",
      },
      size: "150px",
      isVisible: true,//columnsFromStorage[formFields.employeeEmail],
      RowCell: (props) => <LinkCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "120px" }}>
          <SimpleHeader name={t("tableHeader:object_phone")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.EmployeePhoneNumber`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={phone}
                changeSearchValue={setphone}
                {...props}
              />
            )}
          />
        </Box>

      ),
    },
    {
      id: formFields.employeeEmail,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:employeeEmail"),
      styleTableCell: {
        verticalAlign: "top",
      },
      size: "150px",
      isVisible: columnsFromStorage[formFields.employeeEmail],
      RowCell: (props) => <LinkCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "120px" }}>
          <SimpleHeader name={t("tableHeader:employeeEmail")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.employeeEmail}`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={email}
                changeSearchValue={setEmail}
                {...props}
              />
            )}
          />
        </Box>

      ),
    },
    
   /* {
      id: formFields.tasks,
      numeric: false,
      label: t("tableHeader:tasks"),
      size: 80,
      isVisible: columnsFromStorage[formFields.tasks],
      mode: "diagram",
      clickable: false,
      HeaderCell: () => (
        <Box sx={{ width: "80px" }}>
          <SimpleHeader name={t("tableHeader:tasks")} />
          <Box sx={{ height: "24px", padding: "12px 0" }}></Box>
        </Box>

      ),
    },*/
    {
      id: formFields.edit,
      numeric: false,
      enableSorting: false,
      label: "",
      size: 50,
      isVisible: true,
      clickable: false,
    },
  ].filter(({ isVisible }) => isVisible);
