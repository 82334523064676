export const SCHEMA_CANVAS = {
  WIDTH: 800,
  HEIGHT: 400,
};

export const ICON_SIZE = {
  WIDTH: 20,
  HEIGHT: 20,
};

export const dateFormat = "DD.MM.YYYY";
export const dateFormatWithTime = "DD.MM.YYYY hh.mm A";
export const dateFormatWithTimeWithoutA = "DD.MM.YYYY HH:mm";
export const dateFormatWithTimeWithoutAForSubmit = "YYYY-MM-DD HH:mm";
export const dateFormatForSubmit = "YYYY-MM-DD";

export const globalFields = {
 
  completed_jobs_amount: "completed_jobs_amount",
  needs_attention_jobs_amount: "needs_attention_jobs_amount",

  overdued_jobs_amount: "overdued_jobs_amount",
  completed_intime_jobs_amount:"completed_intime_jobs_amount",
  near_deadline_jobs_amount:"near_deadline_jobs_amount",
  not_near_deadline_jobs_amount:"not_near_deadline_jobs_amount",
  total_jobs_amount:"total_jobs_amount",
  fire_messages_amount:"fire_messages_amount",
  false_fire_messages_amount:"false_fire_messages_amount",

  completedInTimeJobsAmount:"completed_intime_jobs_amount",
  nearDeadlineJobsAmount:"near_deadline_jobs_amount",
  notNearDeadlineJobsAmount:"not_near_deadline_jobs_amount",
  totalJobsAmount:"total_jobs_amount",

  completedJobsAmount: "completed_jobs_amount",
  needsAttentionJobsAmount: "needs_attention_jobs_amount",
  overduedJobsAmount: "overdued_jobs_amount",
  fireMessagesAmount:"fire_messages_amount",
  falseFireMessagesAmount:"false_fire_messages_amount",

  all_completed: "all_completed",
  

};
export const CHART_DATA= {
  overdued_jobs_amount: 4,
  completed_intime_jobs_amount:1,
  near_deadline_jobs_amount:2,
  not_near_deadline_jobs_amount:3,

  completedInTimeJobsAmount:1,
  nearDeadlineJobsAmount:2,
  notNearDeadlineJobsAmount:3,
  overduedJobsAmount: 4,
}
export const YANDEX_MAPS_DATA = {
  query: {
    apikey: process.env.REACT_APP_API_KEY,
    lang: "ru_RU",
  },
  defaultState: {
    center: [55.68, 37.73],
    zoom: 7,
    controls: [],
    type: "yandex#map",
  },
  modules: [
    "geocode",
    "SuggestView",
    "geolocation",
    "borders",
    "ObjectManager",
    "clusterer.addon.balloon",
  ],
  defaultOptions: {
    minZoom: 3,
    scrollZoomSpeed: 2,
    suppressMapOpenBlock: true,
  },
};

export const COLORS_DONUT_CHART = {
  
  [globalFields.fireMessagesAmount]: "#fb0104",
  [globalFields.falseFireMessagesAmount]: "#ff6800",
};

export const ORGANIZATIONS = "/organizations";
export const RESPONSIBLE = "/responsible";
export const OBJECTS = "/objects";
export const MAGAZINES = "/magazines";
export const ANALYTICS = "/analytics";
export const FIRE_COMPARTMENTS = "/fire_compartments";
export const EQUIPMENT = "/equipment";
export const TIMETABLE = "/time_table";
export const DIGITIZATION = "/digitization";
export const EMPLOYEES = "/employees";
export const ACTS = "/acts";
export const TASKS = "/tasks";
export const CONTROLLERS = "/controllers";

export const PATHS = {
  LOGIN_ROUTE: "/login",
  REGISTRATION_ROUTE:"/registration",
  RESET_PASSWORD_ROUTE: "/reset_password",
  SET_PASSWORD_ROUTE: "/set_password/:tokenLink",
  SLASH: "/",
  ALL_ALIENS: "*",
  PERFORMERS: `${RESPONSIBLE}/performers`,
  LIST_OBJECTS: `${OBJECTS}/list_objects`,
  ADD_OBJECT: `${OBJECTS}/add_object`,
  ADD_OBJECT_NEW: `${OBJECTS}/add_object_new`,
  EDIT_OBJECT: `${OBJECTS}/list_objects/edit_object/:id`,
  MAGAZINES_NOTIFICATIONS: `${MAGAZINES}/notifications`,
  MAGAZINES_FINISHED_TASKS: `${MAGAZINES}/magazines_finished_tasks`,
  MAGAZINES_EVENTS: `${MAGAZINES}/magazines_events`,
  OBJECTS_MAP: `${OBJECTS}/objects_on_map`,
  GOSTS: "/gosts",
  INTEGRATION: "/integration",
  BILLING: "/billing",
  SETTINGS: "/settings",
  PROFILE_ROUTE: "/profile",
  LIST_EMPLOYEES: `${EMPLOYEES}/list_employees`,
  EDIT_EMPLOYEE: `${EMPLOYEES}/list_employees/edit_employee/:id`,
  GET_EDIT_EMPLOYEE: (id) => `${EMPLOYEES}/list_employees/edit_employee/${id}`,
  ADD_EMPLOYEE: `${EMPLOYEES}/add_employee`,
  CONTROLLERS,
  ADD_CONTROLLER: `${CONTROLLERS}/add_controller`,
  CONTROLLER_MESSAGE_TEMPLATE: `${CONTROLLERS}/:id`,
  GET_CONTROLLER_MESSAGE_TEMPLATE: (id) => `${CONTROLLERS}/${id}`,
  GET_EDIT_MY_ORGANIZATION: (id, typeId) =>
    `${ORGANIZATIONS}/legal_entities/edit_organization/${id}/${typeId}`,
  GET_EDIT_OBJECT: (id) => `${OBJECTS}/list_objects/edit_object/${id}`,
  GET_EDIT_FIRE_COMPARTMENT: (id) =>
    `${FIRE_COMPARTMENTS}/edit_fire_compartment/${id}`,
};

export const ROLE_TYPES = {
  SUPER_ADMIN: "super_admin",
  REGION_ADMIN: "region_admin",
  ADMINISTRATOR_OF_OBJECT: "administrator_of_object",
  SUPERVISOR: "supervisor",
  DEPUTY_SUPERVISOR: "deputy_supervisor",
  DIGITIZER: "digitizer",
  EMPLOYEE: "employee",
  RESPONSIBLE_FOR_PB:"responsible_for_PB",
};

export const ROLES = {
  1: ROLE_TYPES.SUPER_ADMIN,
  2: ROLE_TYPES.REGION_ADMIN,
  3: ROLE_TYPES.ADMINISTRATOR_OF_OBJECT,
  4: ROLE_TYPES.SUPERVISOR,
  5: ROLE_TYPES.DEPUTY_SUPERVISOR,
  6: ROLE_TYPES.DIGITIZER,
  7: ROLE_TYPES.EMPLOYEE,
  8: ROLE_TYPES.RESPONSIBLE_FOR_PB,
};

export const DEFAULT_ROLE_ID = 7;

export const screenSizes = {
  tablet: 910
}

export const RATING_OBJECT_OPTIONS = [
  { size:  0, color: "#00a700" },
  { size: 30, color: "#00a700" },
  { size: 40, color: "#4ed900" },
  { size: 60, color: "#fe8500" },
  { size: 70, color: "#f75317" },
  { size: 100, color: "#ef0000" },
];
