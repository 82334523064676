import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./constants";

export const logsApi = createApi({
  reducerPath: "logs/api",
  tagTypes: ['Logs'],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const { token } = getState().users;
      headers.set("Content-Type", "application/json");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getLogOfWorkDoneOnTasks: build.query({
      query: (params) => {
        const stringParams = Object.entries(params).reduce(
          (acc, [key, value]) => `${acc ? acc + "&" : ""}${key}=${value}`,
          ""
        );
        return {
          url: `api/logs/get-logs-list?${stringParams}`,
        };
      },
      providesTags: ['Logs']
    }),  
    getListEventMessages: build.query({
      query: ({FloorInfoId}) => ({
        url: `api/logs/get-last-event-messages?takeCount=100${FloorInfoId}`,
      }),
      providesTags: ['Logs']
    }),
    getListEventMessagesWithRooms: build.query({
      query: () => ({
        url: `api/logs/get-last-event-messages-with-rooms?takeCount=100`,
      }),
      providesTags: ['Logs']
    }),
    printEventLogsList: build.query({
      query: (params) => {
        const stringParams = Object.entries(params).reduce(
          (acc, [key, value]) => `${acc ? acc + "&" : ""}${key}=${value}`,
          ""
        );
        return {
          url: `api/logs/print-event-logs-list-disp?${stringParams}`,
        };
      },
      providesTags: ['Logs']
    }),
  }),
});

export const { useLazyGetLogOfWorkDoneOnTasksQuery,useLazyGetListEventMessagesQuery,useLazyGetListEventMessagesWithRoomsQuery,useLazyPrintEventLogsListQuery } = logsApi;
