import React, { useMemo, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    IconButton,
    Typography,
    ClickAwayListener,
    Collapse,
    Fade,
    Link,
    Box
} from "@mui/material";
import {
    FullscreenControl,
    GeolocationControl,
    Map,
    Placemark,
    YMaps,
    ZoomControl,
} from "@pbe/react-yandex-maps";
import {
    useSetMessageFireStatusMutation,
    useLazyGetMessageFireInfoQuery
} from "../../../middleware/messageCodeApi";

import dayjs from "dayjs";
import { useAppSelector } from "../../../hooks/redux";
import { useTranslation } from "react-i18next";
import { SimpleTextCell } from "../../pages/Controllers/Components";
import { useAction } from "../../../hooks/actions";
import TableBasic from "../../components/TableBasic";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import MapIcon from '@mui/icons-material/Map';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import DoneIcon from '@mui/icons-material/Done';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import DomainIcon from '@mui/icons-material/Domain';
import CloseIcon from "@mui/icons-material/Close";
import InputTextarea from "../InputTextarea";
import "./style.css";

export const PushEvent = ({ open, setOpen, funtinterval, notifications }) => {
    const dateFormat = "DD.MM.YYYY, HH:mm:ss";
    const { pageNumber } = useAppSelector((state) => state.settings);
    const [Itemdata, setItemdata] = useState({});
    const [openPopper, setopenPopper] = useState(false);
    const [DetailsMessage, setDetailsMessage] = useState("");
    const { changePageNumber } = useAction();
    const { t } = useTranslation();
    const [getmessageFire, { error: errormessageFire, refetch: refetchmessageFire /* isLoading */ }] =
        useLazyGetMessageFireInfoQuery();
    const [
        SetMessageFireStatus,
        {
            error: errorSetMessageFireStatus,
            isSuccess: isSuccessSetMessageFireStatus,
            isLoading: isLoadingSetMessageFireStatus,
        },
    ] = useSetMessageFireStatusMutation();


    const handleToggle = () => {
        setopenPopper((prevOpen) => !prevOpen);
    };

    useEffect(() => {
        if (notifications.length > 0) {
            const messageID = notifications[0].messageId
            getmessageFire({ messageId: messageID }).then((data) => setItemdata(data.data));
        }
    }, [notifications]);
    /*const Itemdata = {
        objectName: "ТЦ Пример",
        address: { city: "Иркутск", street: "ленина", building: "1" },
        phoneNumber: "8-3952-125-523",
        objectAvatar: "https://backet-dozor-1.s3.yandexcloud.net/uploaded_files/3e391707-20db-4b58-a57d-5bb699072ed5/1569cb15-1116-4385-809c-69c173a01e2f",
        moreInfo: "Коментарий к событию",
        message: {
            class_message: "Внимание",
            plume_message: "001",
        },
        responsibleCustomer: "Иванов Иван Иванович",
        responsibleCustomerPhone: "8987654321",
        responsibleUser: "ООО Салют",
        responsibleUserPhone: "5562259784",

    }*/
    const changeDetailes = (e) => {
        setDetailsMessage(e.target.value)
    }

    const setFireStatus = (status) => {
        SetMessageFireStatus({ MessageId: notifications[0].messageId, Details: DetailsMessage, Status: status }).then((result) => {
            if ("error" in result) {
                return;
            }
            setDetailsMessage("")
            funtinterval()
        });
    }
    console.log("itemdata", Itemdata)
    return (
        <div>
            <Dialog
                open={open}
                className="modal select_columns"
                maxWidth="lg"
            >
                <DialogTitle className="modal-title-between border_bottom">
                    <Box>
                        <Box className="push-box-item" >
                            <DomainIcon className="pushIcon" />
                            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                                {`${(Itemdata?.protectObjectName && Itemdata?.protectObjectName != null) ? Itemdata?.protectObjectName : ""}`}
                            </Typography>

                        </Box>
                        <Box className="push-box-item" >
                            <FmdGoodIcon className="pushIcon" />
                            <Link href="#" onClick={handleToggle} underline="hover" sx={{ display: "flex", color: "var(--main)" }} >
                                {`${Itemdata?.protectionObjectAddress?.city}, ${Itemdata?.protectionObjectAddress?.street} ${Itemdata?.protectionObjectAddress?.building}`}
                                {!openPopper ? <ExpandMoreIcon className="pushIcon" sx={{ marginLeft: "15px" }} /> : <ExpandLessIcon className="pushIcon" sx={{ marginLeft: "15px" }} />}

                            </Link>


                        </Box>
                        <Collapse in={openPopper}>
                            <YMaps>
                                <Map defaultState={{ center: [Itemdata?.protectionObjectAddress?.coordinates?.latitude, Itemdata?.protectionObjectAddress?.coordinates?.longitude], zoom:15 }}>
                                    <Placemark geometry={[Itemdata?.protectionObjectAddress?.coordinates?.latitude, Itemdata?.protectionObjectAddress?.coordinates?.longitude]} />
                                </Map>
                            </YMaps>
                        </Collapse>
                        <Box className="push-box-item" >
                            <ContactPhoneIcon className="pushIcon" />
                            <Link href={`tel:${Itemdata?.protectionObjectPhone}`} underline="hover" sx={{ color: "var(--main)" }} >
                                {`${(Itemdata?.protectionObjectPhone && Itemdata?.protectionObjectPhone != null) ? Itemdata?.protectionObjectPhone : ""}`}
                            </Link>

                        </Box>
                        <Box className="push-box-item" >
                            <QueryBuilderIcon className="pushIcon" />
                            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                                {`${(Itemdata?.reactionTime && Itemdata?.reactionTime != null) ? dayjs(Itemdata?.reactionTime).format(dateFormat) : ""}`}
                            </Typography>

                        </Box>
                    </Box>

                </DialogTitle>
                <DialogContent sx={{ padding: "20px 36px" }}>
                    <Box sx={{ marginTop: "15px", marginBottom: "15px" }}>
                        <img
                            style={{    width: "400px"}}
                            src={Itemdata?.protectionObjectPhoto || "/unnamed.png"}
                            alt={Itemdata?.protectionObjectPhoto || "unnamed"}
                        />
                    </Box>
                    <Box className="push-box-item" >
                    <Typography sx={{ fontWeight: "bold", fontSize: "16px", marginTop: "15px", marginBottom: "10px",width:"150px" }}>
                            {`Класс сообщения: `}
                        </Typography>
                        <Typography sx={{ fontWeight: "bold", fontSize: "16px",marginLeft:"15px", marginTop: "15px", marginBottom: "10px" }}>
                            {` ${(Itemdata?.messageCodeTypeName && Itemdata?.messageCodeTypeName != null) ? Itemdata?.messageCodeTypeName : ""}`}
                        </Typography>
                    </Box>
                    <Box className="push-box-item" >
                    <Typography sx={{ fontWeight: "bold", fontSize: "16px", marginBottom: "10px",width:"150px" }}>
                            {`Зона,шлейф: `}
                        </Typography>
                        <Typography sx={{ fontWeight: "bold", fontSize: "16px",marginLeft:"15px", marginBottom: "10px" }}>
                            {` ${(Itemdata?.plume && Itemdata?.plume != null) ? Itemdata?.plume : ""}`}
                        </Typography>
                    </Box>
                    <Box className="input-block-field" sx={{ marginTop: "15px", width: "100%" }}>
                        <InputTextarea value={DetailsMessage} onChange={(e) => changeDetailes(e)} label={"Дополнительная информация"} />
                    </Box>
                    {/* <Typography sx={{ fontWeight: "bold", fontSize: "16px", marginTop: "15px", marginBottom: "10px" }}>
                        {`Ответственный заказчик:`}
                    </Typography>
                    <Box className="push-box-item" >
                        <AccountCircleIcon className="pushIcon" />
                        <Typography  >
                            {`${(Itemdata?.responsibleCustomer && Itemdata?.responsibleCustomer != null) ? Itemdata?.responsibleCustomer : ""}`}
                        </Typography>
                    </Box>
                    <Box className="push-box-item" >
                        <LocalPhoneIcon className="pushIcon" />
                        <Link href={`tel:${Itemdata?.responsibleUserPhone}`} variant="subtitle1" underline="hover" sx={{ color: "var(--main)" }} >
                            {`${(Itemdata?.responsibleCustomerPhone && Itemdata?.responsibleCustomerPhone != null) ? Itemdata?.responsibleCustomerPhone : ""}`}
                        </Link>
                    </Box>
                    <Typography sx={{ fontWeight: "bold", fontSize: "16px", marginTop: "15px", marginBottom: "10px" }}>
                        {`Ответственный исполнитель:`}
                    </Typography>
                    <Box className="push-box-item" >
                        <AccountCircleIcon className="pushIcon" />
                        <Typography  >
                            {`${(Itemdata?.responsibleUser && Itemdata?.responsibleUser != null) ? Itemdata?.responsibleUser : ""}`}
                        </Typography>
                    </Box>
                    <Box className="push-box-item" >
                        <LocalPhoneIcon className="pushIcon" />
                        <Link href={`tel:${Itemdata?.responsibleUserPhone}`} variant="subtitle1" underline="hover" sx={{ color: "var(--main)" }} >
                            {`${(Itemdata?.responsibleUserPhone && Itemdata?.responsibleUserPhone != null) ? Itemdata?.responsibleUserPhone : ""}`}
                        </Link>
                    </Box>
    */}
                </DialogContent>
                <DialogTitle>
                    <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "40px" }}>
                        <Button onClick={() => setFireStatus(1)} sx={{ width: "48%" }} variant="contained" color="success" startIcon={<DoneIcon />}>
                            Подтверждение
                        </Button>
                        <Button onClick={() => setFireStatus(2)} sx={{ width: "48%" }} variant="contained" color="error" startIcon={<CloseIcon />}>
                            Отмена
                        </Button>

                    </Box>

                </DialogTitle>
            </Dialog>
        </div>
    );
};
