import React, { useEffect } from "react";
import { Backdrop, Box, CircularProgress, Dialog, IconButton, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useGetProfileInfoByIdQuery } from "../../../middleware/usersApi";
import { useAppSelector } from "../../../hooks/redux";
import ProfileMainDataForm from "../Profile/ProfileMainDataForm";
import { useAction } from "../../../hooks/actions";

export const EmployeeDialog = ({ open, onClose, employeeId }) => {
  const { setIsErrorRequest, setIsNoPermissionRequest } = useAction();
  const {
    data: employee,
    isLoading: isLoadingEmployee,
    refetch: refetchProfileByIdData,
    error,
  } = useGetProfileInfoByIdQuery({ employeeId });

  const token = useAppSelector((state) => state.users.token);

  useEffect(() => {
    refetchProfileByIdData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (error) {
      error.originalStatus === 403
        ? setIsNoPermissionRequest(true)
        : setIsErrorRequest(error.originalStatus);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  if (isLoadingEmployee) {
    return (
      <Backdrop sx={{ color: "var(--white)", zIndex: 9999 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    !error && <Dialog className="object_dialog employee-modal" open={open} onClose={onClose}>
      <DialogTitle sx={{ mb: 2, p: 2 }}>
        <IconButton className="close_icon" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box 
      
        sx={{ maxHeight: "83vh" }}
      >
        <ProfileMainDataForm
          isEmpoyee={true}
          profileData={employee}
          token={token}
        />
      </Box>
    </Dialog>
  );
};
