import React from 'react';
import "./styles/styles.css";
import { useAppSelector } from "../../../../../hooks/redux";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const Index = () => {

    const { t } = useTranslation()

    const { role, jobTitle, userEmployers } = useAppSelector(state => state.users.info);

    return (
        <Box className="read_only_personal_data_container">
            <Box className="read_only_personal_data_container-item">
                <Typography className="read_only_personal_data_container-item-label">
                    {t("label:role")}
                </Typography>
                <Typography className="read_only_personal_data_container-item-content">
                    {role ? role : t("label:doesntHave")}
                </Typography>
            </Box>
          {/*  <Box className="read_only_personal_data_container-item">
                <Typography className="read_only_personal_data_container-item-label">
                    {t("label:position")}
                </Typography>
                <Typography className="read_only_personal_data_container-item-content">
                    { jobTitle ? jobTitle : t("label:doesntHave")}
    </Typography>
    
            </Box>*/}
           {/* <Box className="read_only_personal_data_container-item">
                <Typography className="read_only_personal_data_container-item-label">
                    {t("label:organization")}
                </Typography>
                {userEmployers?.length ?
                    userEmployers.map((item, i) => (
                        <Typography key={i} className="read_only_personal_data_container-item-content">
                            {item}
                        </Typography>
                    )) : (
                        <Typography className="read_only_personal_data_container-item-content">
                            {t("label:doesntHave")}
                        </Typography>
                    )
                }
            </Box>
            */}
        </Box>
    )

}

export default Index;