import React from "react";
import "./styles-profile/styles.css";
import { useAppSelector } from "../../../../hooks/redux";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getMaskPhone } from "../../../../helpers/helper";

export const ReadOnlyEmployeeContactInfo = () => {
  const { t } = useTranslation();

  const { phone, email } = useAppSelector((state) => state.users.employee);

  return (
    <Box
      style={{ display: "flex", flexWrap: "wrap" }}
      className="read_only_personal_data_container"
    >
      <Box className="read_only_personal_data_container-item">
        <Typography className="read_only_personal_data_container-item-label">
          {t("label:phone")}
        </Typography>
        <Typography className="read_only_personal_data_container-item-content">
          {phone ? getMaskPhone(phone) : t("label:doesntHave")}
        </Typography>
      </Box>
      <Box className="read_only_personal_data_container-item">
        <Typography className="read_only_personal_data_container-item-label">
          {t("label:email")}
        </Typography>
        <Typography className="read_only_personal_data_container-item-content">
          {email ? email : t("label:doesntHave")}
        </Typography>
      </Box>
    </Box>
  );
};
