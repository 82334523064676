import React from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Box, Typography } from "@mui/material";
import { ratingConfig } from "./config";
import { useLazyGetSystemOverallAnalyticsQuery } from "../../../middleware/analyticsApi";
import { useAppSelector } from "../../../hooks/redux";
import { useEffect } from "react";
import { useAction } from "../../../hooks/actions";
import { useNavigate } from "react-router-dom";
import { LinarRatingBox } from "./LinarRatingBox";
import { PATHS } from "../../../constants";

export const RatingBox = () => {
  const { analyticData } = useAppSelector((state) => state.analytics);

  const [getAnalytics, { error:ratingError, isLoading }] =
  useLazyGetSystemOverallAnalyticsQuery();
  useEffect(() => {
    getAnalytics()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { setIsErrorRequest } = useAction();
  const navigate = useNavigate();
  const getTextColor = (value) => {
    return value >= 0 ? "green" : "red";
  };
  const getStatus = (value) => {
    return value >= 0 ? "success" : "error";
  };
  const getTransform = (value) => {
    return value >= 0 ? "rotate(0deg)" : "rotate(180deg)";
  };
  const handleClick = (nameforLink) => {
    if (nameforLink == "objects" ) navigate(PATHS.LIST_OBJECTS)
    if (nameforLink == "totalJob" ) navigate(PATHS.LIST_OBJECTS + `?ShowObjectInFireAlarmsPerActiveSession=${true}`)
    if (nameforLink == "overdued_jobs_amount") navigate(PATHS.MAGAZINES_EVENTS + `?ShowOnlyFireMessagesPerActiveSession=${true}`)
    if (nameforLink == "near_deadline_jobs_amount") navigate(PATHS.MAGAZINES_EVENTS + `?ShowOnlyFireMessagesPerActiveSession=${false}`)
  }
  const error = ratingError;

  useEffect(() => {
    setIsErrorRequest(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <>
      {!isLoading && (
        <Box className="analytics_panel" >
          {ratingConfig(analyticData).map((item, index) => (
            <Box key={index} className="analytics_panel-block" onClick={() => handleClick(item.nameforLink)}>
              <Typography className="analytics_panel-block-name" sx={{ color: item.id == 2 ? "red !important" : "black" }}>

                {item.name}
              </Typography>

              <Box className="analytics_panel-block-wrapper">
                <Typography
                  className="analytics_panel-block-amount"
                  sx={{ color: item?.color }}
                >
                  {item.amount}
                </Typography>

                {(item.id == 3 || item.id == 4) && <> <Typography
                  className="analytics_panel-block-percentage"
                  sx={{ color: `${item?.percentage ? getTextColor(item?.percentage) : 0}` }}
                >
                  {Number.isInteger(item?.percentage) ? item.percentage : 0}%
                </Typography>
                  <ArrowDropUpIcon
                    sx={{
                      transform: getTransform(item.percentage),
                    }}
                    fontSize={"large"}
                    color={getStatus(item.percentage)}
                  />
                </>
                }
              </Box>
            </Box>
          ))}
        </Box>
      )}
      {/*<LinarRatingBox />*/}
    </>
  );
};
