import React, { useState, useEffect, useMemo } from "react";
import { Box, Typography, Avatar, Link } from "@mui/material";
import { useGetUserJobTitlesSelectQuery } from "../../../middleware/usersApi";
import SelectSimple from "../../components/SelectSimple";
import { getUserJobTitlesOptions,formatPhoneNumber } from "./helpers";
import SelectGrouping from "../../components/SelectGrouping";
import { useGetOrganizationTypesQuery } from '../../../middleware/apiOrganizations';
import { useGetEquipmentSystemsWithSearchQuery } from "../../../middleware/equipmentsApi";

export const BlockAvatar = ({ data }) => {
  return (
    <Box className="avatar_block">
      <Avatar alt={"avatar_block"} src={data} />
    </Box>
  );
};
export const SelectSimpleEquipmentKinds = (props) => {
  /* const { facilitySelectElements } = useAppSelector(
     (state) => state.facilities
   );*/
  const [searchValue, setSearchValue] = useState("");
  const { data, refetch } = useGetEquipmentSystemsWithSearchQuery({ name: searchValue });
  console.log("data", data)
  useEffect(() => {
    searchValue && refetch();
  }, [searchValue, refetch])

  const options = useMemo(() => {
    return data
      ? [{ value: "все", name: "все" }, ...data]//getFacilitiesOptionsGroup(data)
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <SelectSimple
      {...props}
      setSearchValue={setSearchValue}
      searchValue={searchValue}
      options={options}
      defaultValue={"все"}
    //bgcolor={bgcolor}
    />
  );
  //return <SelectSimple options={facilitySelectElements || []} {...props} />;
};
export const ClickableActionNameCell = ({ item }) => {
  const { name, isActive, action } = item || {};
  return (
    <Typography
      sx={{ color: isActive ? "var(--main)" : "red" }}
      className={name ? "table-row-cell-link" : ""}
      onClick={action ? action : () => { }}
    >
      {name || ""}
    </Typography>
  );
};

export const ClickableActionListCell = ({ item }) => {
  return item?.map((e, i) => <ClickableActionNameCell key={i} item={e} />);
};

export const SimpleTextCell = ({ item }) => {
  return <Typography>{item}</Typography>;
};
export const LinkCell = ({ item }) => {
  const { link, type } = item || {};
  return <Link href={`${type}${type == "tel:" ? formatPhoneNumber(link) : link}`} variant="subtitle1" underline="hover" style={{ color: "var(--main)" }}>
    {link}
  </Link>;
};
export const SimpleActiveTextCell = ({ item }) => {
  let color;
  switch (item) {
    case "Активен":
      color = "#396c4b";
      break;
    case "Активен/требуется подтверждение":
      color = "#0070c0";
      break;
    case "Заблокирован":
      color = "#ff0000";
      break;
    default:
      color = "#00b050";
      break;
  }
  return <Typography sx={{ color: color }}>{item}</Typography>;
};

export const SimpleBlockTextCell = ({ item }) => {
  const blockText = item.split(", ");
  return blockText?.map((text, i) => (
    <Typography key={i} sx={{ lineHeight: "17px !important", marginBottom: "10px" }}>
      {text || ""}
    </Typography>
  ));
};


export const SelectSimpleJobTitles = (props) => {
  const { data: jobTitlesSelectOptions = [] } =
    useGetUserJobTitlesSelectQuery();

  return (
    <SelectSimple
      {...props}
      options={getUserJobTitlesOptions(jobTitlesSelectOptions)}
    />
  );
};

export const SelectOrganizationTypes = (props) => {
  const { data: organizationTypes = [] } = useGetOrganizationTypesQuery();

  return (
    <SelectSimple
      {...props}
      options={getUserJobTitlesOptions(organizationTypes)}
    />
  );
}
