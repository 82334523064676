import InputText from "../../../components/InputText";
import { ObjectsSelect } from '../Components';

export const formFields = {
  name: "name",
  protectionObjectIds: "protectionObjectIds",
};

export const config = [
  {
    name: formFields.name,
    label: "tableHeader:name",
    type: "text",
    required:true,
    flexBasis: "30%",
    input: (props) => <InputText {...props} />,
  },
  {
    name: formFields.protectionObjectIds,
    type: "select-multi",
    label: "label:objects",
    input: (props) => <ObjectsSelect {...props} />,
  },
];
