import React,{useState} from "react";
import { Box, Typography, InputAdornment, InputLabel } from "@mui/material";

import {
  potentialRadiationHazardOptions,
  fireResistanceDegreeOptions,
  structuralFireHazardClassOptions,
  fireAndExplosionHazard,
  functionFireSecurityClasses,
  timezones,
  ventilationTypeOptions,
  riskCategory,
  SNCEOptions,
  AFASOptions,
  typesAlarmOptions,
  AFSSTOptions,
  voltageOptions,
  typeWiringOptions,
} from "./options";

import {SelectMultipleChipDeletableWithSearchField} from "../../components/SelectMultipleChipDeletableWithSearchField";
import CheckboxSimple from "../../components/CheckboxSimple";
import InputText from "../../components/InputText";
import SelectSimple from "../../components/SelectSimple";
import DatePickerField from "../../components/DatePickerField";
import { t } from "i18next";
import { InputFileMultiple } from "../../components/InputFile/InputFileMultiple";
import InputFileSingle from "../../components/InputFile/InputFileSingle";
import YandexMapInput from "../../components/YandexMapInput";
import ToggleButtonsGroup from "../../components/ToggleButtonsGroup";
import RiskCategoryButton from "../../components/ToggleButtonsGroup/RiskCategoryButton";
import { useGetOrganizationsSelectQuery } from "../../../middleware/apiOrganizations";
import { fileSizeCheck, fileTypeCheck } from "../../../validation";
import { useAppSelector } from "../../../hooks/redux";

const OrganizationsSelect = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const { data: organizationsSelect = [] } =
  useGetOrganizationsSelectQuery( searchValue );
  
  return <SelectMultipleChipDeletableWithSearchField 
  {...props}  setSearchValue={setSearchValue} isIds={true}
  searchValue={searchValue} options={organizationsSelect} />;
};
export const allColumns = [
  { name: "numberAndNameObjects", showName: "facilityName", value: true },
  { name: "facilityOverallRating", value: true, showName: "facilityOverallRating" },
  { name: "equipmentRegistry",showName:"equipment", value: true },
  { name: "address",showName:"address", value: true },
  { name: "tasks",showName:"tasks", value: true },
];

export const getConfigFormModalSelect = (columns) =>
  columns?.map(({ name }) => ({
    name,
    label: `tableHeader:${name}`,
    type: "checkbox",
    rules: {},
    flexBasis: "50%",
    input: (props) => <CheckboxSimple {...props} />,
  }));
export const formFields = {
  organization_section: "organization_section",
  buildingPassport: "buildingPassport",
  buildingCharacteristics: "buildingCharacteristics",
  facilitySystems: "facilitySystems",

  ventilationType: "ventilationType",
  riskCategoryAssignmentDate: "riskCategoryAssignmentDate",

  organizations: "organizations",
  organizationsSection: "organizations",

  floorsCount: "floorsCount",
  height: "height",
  width: "width",
  length: "length",
  square: "square",
  potentialRadiationHazardCategory: "potentialRadiationHazardCategory",
  fireResistanceDegree: "fireResistanceDegree",
  structuralFireHazardClass: "structuralFireHazardClass",
  fireAndExplosionHazardOutdoorInstCategory:
    "fireAndExplosionHazardOutdoorInstCategory",
  evacuationExitsCount: "evacuationExitsCount",
  simultaneousPeopleCount: "simultaneousPeopleCount",
  elevatorToTransportFirefightersAvailability:
    "elevatorToTransportFirefightersAvailability",
  stairwellsCount: "stairwellsCount",
  basementFloorAvailability: "basementFloorAvailability",
  groundFloorAvailability: "groundFloorAvailability",
  internalFireWaterSupplyAvailability: "internalFireWaterSupplyAvailability",
  fireWaterSupplyMaintenanceContractAvailability:
    "fireWaterSupplyMaintenanceContractAvailability",
  fireHosesCount: "fireHosesCount",
  ventilationAvailability: "ventilationAvailability",
  fuelForStandaloneHeatingType: "fuelForStandaloneHeatingType",
  externalFireWaterSupplyAvailability: "externalFireWaterSupplyAvailability",
  specializedOrganizationProtectionInvolvement:
    "specializedOrganizationProtectionInvolvement",
  fireHydrantsCount: "fireHydrantsCount",

  facilityName: "facilityName",
  buildingFacadePhoto: "buildingFacadePhoto",
  mainFunctionalPurpose: "mainFunctionalPurpose",
  cadastralNumber: "cadastralNumber",
  objectAddress: "address",
  gmtTimeZone: "gmtTimeZone",
  facilityIntegrationNumber: "facilityIntegrationNumber",
  assignedMobileActionSquad: "assignedMobileActionSquad",
  objectType: "type",
  objectKind: "kind",
  riskCategory: "riskCategory",
  commissioningDate: "commissioningDate",
  registrationDate: "registrationDate",
  riskCategoryAssignment: "riskCategoryAssignment",
  lastCheckDate: "lastCheckDate",
  hazardClass: "hazardClass",
  nearestFireStation: "nearestFireStation",
  roadsForSpecialEquipmentAvailability: "roadsForSpecialEquipmentAvailability",
  bordersWithForestAreasAvailability: "bordersWithForestAreasAvailability",
  specTechSpecificationsAvailability: "specTechSpecificationsAvailability",
  specialTechnicalSpecifications: "specialTechnicalSpecifications",
  fireSafetyDeclarationAvailability: "fireSafetyDeclarationAvailability",
  fireSafetyDeclaration: "fireSafetyDeclaration",
  fireRisksCalculationAvailability: "fireRisksCalculationAvailability",
  notifAndEvacControlSysMaintenanceContract:"notifAndEvacControlSysMaintenanceContract",
  fireRisksCalculation: "fireRisksCalculation",
  controlledPersonIndividCalcAvailability:
    "controlledPersonIndividCalcAvailability",
  individualizationOfTheControlledPersonCalculation:
    "individualizationOfTheControlledPersonCalculation",

  projectDocumentation: "projectDocumentation",
  technicalDocumentation: "technicalDocumentation",
  permitDocumentation: "permitDocumentation",

  specialTechnicalSpecificationsDocumentation:
    "specialTechnicalSpecificationsDocumentation",
  fireSafetyDeclarationDocumentation: "fireSafetyDeclarationDocumentation",
  calculationOFfireRisksDocumentation: "calculationOFfireRisksDocumentation",
  calculationOfPLIndividualisationIndexDocumentation:
    "calculationOfPLIndividualisationIndexDocumentation",

  fireCompartmentsCount: "fireCompartmentsCount",

  notifAndEvacControlSysAvailable: "notifAndEvacControlSysAvailable",
  notifAndEvacControlSysType: "notifAndEvacControlSysType",
  notificationAndEvacSysContract:
    "notificationAndEvacSysContract",
    notificationAndEvacSysContractNumber:"notificationAndEvacSysContractNumber",
    notifAndEvacSysContractStartDate:"notifAndEvacSysContractStartDate",
    notifAndEvacSysContractEndDate:"notifAndEvacSysContractEndDate",
    notifAndEvacSysContractOrgId:"notifAndEvacSysContractOrgId",
    snmeContractFile:"snmeContractFile",
    afaContractFile:"afaContractFile",
    automaticFireAlarmSysContract:"automaticFireAlarmSysContract",
    automaticFireAlarmSysContractNumber:"automaticFireAlarmSysContractNumber",
    automaticFireAlarmSysContractType:"automaticFireAlarmSysContractType",
    automaticFireAlarmSysContractStartDate:"automaticFireAlarmSysContractStartDate",
    automaticFireAlarmSysContractEndDate:"automaticFireAlarmSysContractEndDate",
    automaticFireAlarmSysContractExecOrgId:"automaticFireAlarmSysContractExecOrgId",
  notifAndEvacControlSysInstallationDate:
    "notifAndEvacControlSysInstallationDate",
  notifAndEvacControlSysOverhaulDate: "notifAndEvacControlSysOverhaulDate",

  autoFireAlarmSysAvailable: "autoFireAlarmSysAvailable",
  autoFireAlarmSysType: "autoFireAlarmSysType",
  duplicatingToTheRemoteControl: "duplicatingToTheRemoteControl",
  autoFireAlarmSysMaintenanceContract: "autoFireAlarmSysMaintenanceContract",
  autoFireAlarmSysInstallationDate: "autoFireAlarmSysInstallationDate",
  autoFireAlarmSysOverhaulDate: "autoFireAlarmSysOverhaulDate",
  detectorsType: "detectorsType",
  autoFireSuppressionSysAvailable: "autoFireSuppressionSysAvailable",
  autoFireSuppressionSysType: "autoFireSuppressionSysType",
  autoFireSuppressionSysMaintenanceContract:
    "autoFireSuppressionSysMaintenanceContract",
    automaticFireSupSysContractNumber:"automaticFireSupSysContractNumber",
    automaticFireSubSysContractType:"automaticFireSubSysContractType",
    automaticFireSupSysContractStartDate:"automaticFireSupSysContractStartDate",
    automaticFireSupSysContractEndDate:"automaticFireSupSysContractEndDate",
    automaticFireSupSysContractExecOrgId:"automaticFireSupSysContractExecOrgId",
    afasysContractFile:"afasysContractFile",
  autoFireSuppressionSysInstallationDate:
    "autoFireSuppressionSysInstallationDate",
  autoFireSuppressionSysOverhaulDate: "autoFireSuppressionSysOverhaulDate",
  electricityAvailable: "electricityAvailable",
  notifAndEvacSysContractType:"notifAndEvacSysContractType",
  voltage: "voltage",
  wiringType: "wiringType",
  electricityInstallationDate: "electricityInstallationDate",
  electricityOverhaulDate: "electricityOverhaulDate",
  gasAvailable: "gasAvailable",
  gasInstallationDate: "gasInstallationDate",
  gasOverhaulDate: "gasOverhaulDate",
  fullTimeStayAvailability: "fullTimeStayAvailability",
  newYearMassAvailabilityOfChildren: "newYearMassAvailabilityOfChildren",
  storesAndSellsPyrotechnicProducts: "storesAndSellsPyrotechnicProducts",
  involvedInElections: "involvedInElections",
  particularlyHazardousAndTechnicallyComplex:
    "particularlyHazardousAndTechnicallyComplex",
  criticalToCountriesNationalSecurity: "criticalToCountriesNationalSecurity",
  hazardousProductionFacilitiesAvailability:
    "hazardousProductionFacilitiesAvailability",
  bookRepositoryOrArchiveOfFederalImportance:
    "bookRepositoryOrArchiveOfFederalImportance",

  object: "object",
  fireCompartmentPassport: "fireCompartmentPassport",
  fireCompartmentCharacteristic: "fireCompartmentCharacteristic",
  files: "files",

  notifAndEvacControlSysEquipAmount: "notifAndEvacControlSysEquipAmount",
  autoFireAlarmSysEquipAmount: "autoFireAlarmSysEquipAmount",
  autoFireSuppressionSysEquipAmount: "autoFireSuppressionSysEquipAmount",
  fireCompartments: "fireCompartments",
};

export const sections = [
  {
    id: 1,
    name: formFields.organizationsSection,
    label: "label:organizations",
    items: [
      {
        name: formFields.organizations,
        label: "label:organizations",
        type: "select-multi",
        input: (props) => <OrganizationsSelect {...props} />,
      },
    ],
  },
  {
    id: 2,
    name: formFields.buildingPassport,
    label: "label:buildingPassport",
    items: [
      {
        name: formFields.facilityName,
        label: "label:facilityName",
        placeholder: "placeholder:facilityName",
        type: "text",
        rules: {
          required: true,
        },
        input: (props) => {
          const { name, label, ...rest } = props;
          return (
            <Box sx={{ minWidth: "250px" }}>
              <InputLabel sx={{ mb: "6px" }} htmlFor={name}>
                {t(label)}
              </InputLabel>
              <InputText id={name} name={name} {...rest} />
            </Box>
          );
        },
      },
      {
        name: formFields.buildingFacadePhoto,
        label: "label:buildingFacadePhoto",
        type: "file",
        rules: {
          validate: {
            type: (files) => {
              const availableFileTypes = ["image/jpeg", "image/png"];
              return fileTypeCheck(
                files.filesToAdd,
                formFields.buildingFacadePhoto,
                availableFileTypes
              );
            },
            size: (files) => {
              const maxAvailableFileSize = 10; // MBs
              return fileSizeCheck(
                files.filesToAdd,
                formFields.buildingFacadePhoto,
                maxAvailableFileSize
              );
            },
          },
        },
        canDeleteFile: false,
        preview: true,
        input: (props) => (
          <Box sx={{ maxWidth: "49%" }}>
            <InputFileSingle {...props} accept={".png, .jpg, .jpeg"} />
          </Box>
        ),
      },
      {
        name: formFields.mainFunctionalPurpose,
        label: "label:mainFunctionalPurpose",
        type: "text",
        rules: {},
        flexBasis: "49%",
        input: (props) => <InputText {...props} />,
      },
      {
        name: formFields.cadastralNumber,
        label: "label:cadastralNumber",
        type: "text",
        rules: {},
        flexBasis: "49%",
        input: (props) => <InputText {...props} />,
      },
      {
        name: formFields.objectAddress,
        label: "label:objectAddress",
        type: "address",
        rules: {
          validate: {
            requiredSubItems: (value) => {
              if (!value) {
                return;
              }
            },
          }
        },
        shouldSetCoordinates: true,
        input: (props) => <YandexMapInput {...props} />,
      },
      {
        name: formFields.gmtTimeZone,
        label: "label:gmtTimeZone",
        type: "select-number",
        options: timezones,
        rules: {},
        flexBasis: "49%",
        input: (props) => <SelectSimple {...props} />,
      },
      {
        name: formFields.facilityIntegrationNumber,
        label: "label:facilityIntegrationNumber",
        type: "text",
        rules: {
          required: true,
        },
        flexBasis: "49%",
        input: (props) => <InputText {...props} />,
      },
      {
        name: formFields.assignedMobileActionSquad,
        label: "label:assignedMobileActionSquad",
        type: "text",
        rules: {},
        input: (props) => <InputText {...props} />,
      },
      {
        name: formFields.objectType,
        label: "label:objectType",
        type: "text",
        rules: {},
        flexBasis: "49%",
        input: (props) => <InputText {...props} />,
      },
      {
        name: formFields.objectKind,
        label: "label:objectKind",
        type: "text",
        rules: {},
        flexBasis: "49%",
        input: (props) => <InputText {...props} />,
      },
      {
        name: formFields.riskCategory,
        label: "label:riskCategory",
        type: "text",
        rules: {},
        options: riskCategory,
        CustomOptionItem: RiskCategoryButton,
        input: (props) => (
          <Box sx={{ maxWidth: "50%" }}>
            <ToggleButtonsGroup {...props} />
          </Box>
        ),
      },
      {
        name: formFields.commissioningDate,
        label: "label:commissioningDate",
        type: "date",
        rules: {},
        flexBasis: "49%",
        sx: { width: "100%" },
        input: (props) => <DatePickerField {...props} />,
      },
      {
        name: formFields.registrationDate,
        label: "label:registrationDate",
        type: "date",
        rules: {},
        flexBasis: "49%",
        sx: { width: "100%" },
        input: (props) => <DatePickerField {...props} />,
      },
      {
        name: formFields.riskCategoryAssignment,
        label: "label:riskCategoryAssignment",
        type: "text",
        rules: {},
        flexBasis: "49%",
        input: (props) => <InputText {...props} />,
      },
      {
        name: formFields.lastCheckDate,
        label: "label:lastCheckDate",
        type: "date",
        rules: {},
        flexBasis: "49%",
        sx: { width: "100%" },
        input: (props) => <DatePickerField {...props} />,
      },
      {
        name: formFields.hazardClass,
        label: "label:hazardClass",
        type: "select-text",
        rules: {},
        flexBasis: "49%",
        options: functionFireSecurityClasses,
        input: (props) => <SelectSimple {...props} />,
      },
      {
        name: formFields.nearestFireStation,
        label: "label:nearestFireStation",
        type: "text",
        rules: {},
        flexBasis: "49%",
        input: (props) => <InputText {...props} />,
      },
      {
        name: formFields.roadsForSpecialEquipmentAvailability,
        label: "label:roadsForSpecialEquipmentAvailability",
        type: "checkbox",
        rules: {},
        flexBasis: "49%",
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.bordersWithForestAreasAvailability,
        label: "label:bordersWithForestAreasAvailability",
        type: "checkbox",
        rules: {},
        flexBasis: "49%",
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.specTechSpecificationsAvailability,
        label: "label:specTechSpecificationsAvailability",
        type: "checkbox",
        rules: {},
        display: "flex",
        expended: [
          {
            name: formFields.specialTechnicalSpecifications,
            type: "file",
            rules: {
              validate: {
                type: (files) => {
                  const availableFileTypes = ["image/jpeg", "image/png", "application/pdf"];
                  return fileTypeCheck(files.filesToAdd, formFields.specialTechnicalSpecifications, availableFileTypes);
                },
                size: (files) => {
                  const maxAvailableFileSize = 10; // MBs
                  return fileSizeCheck(
                    files.filesToAdd,
                    formFields.specialTechnicalSpecifications,
                    maxAvailableFileSize
                  );
                },
              },
            },
            canDeleteFile: false,
            preview: false,
            input: (props) => <InputFileSingle {...props} accept={".png, .jpg, .jpeg, .pdf"} />,
          },
        ],
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.fireSafetyDeclarationAvailability,
        label: "label:fireSafetyDeclarationAvailability",
        type: "checkbox",
        rules: {},
        display: "flex",
        expended: [
          {
            name: formFields.fireSafetyDeclaration,
            type: "file",
            rules: {
              validate: {
                type: (files) => {
                  const availableFileTypes = ["image/jpeg", "image/png", "application/pdf"];
                  return fileTypeCheck(files.filesToAdd, formFields.fireSafetyDeclaration, availableFileTypes);
                },
                size: (files) => {
                  const maxAvailableFileSize = 10; // MBs
                  return fileSizeCheck(
                    files.filesToAdd,
                    formFields.fireSafetyDeclaration,
                    maxAvailableFileSize
                  );
                },
              },
            },
            canDeleteFile: false,
            preview: false,
            input: (props) => <InputFileSingle {...props} accept={".png, .jpg, .jpeg, .pdf"} />,
          },
        ],
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.fireRisksCalculationAvailability,
        label: "label:fireRisksCalculationAvailability",
        type: "checkbox",
        rules: {},
        display: "flex",
        expended: [
          {
            name: formFields.fireRisksCalculation,
            type: "file",
            rules: {
              validate: {
                type: (files) => {
                  const availableFileTypes = ["image/jpeg", "image/png", "application/pdf"];
                  return fileTypeCheck(files.filesToAdd, formFields.fireRisksCalculation, availableFileTypes);
                },
                size: (files) => {
                  const maxAvailableFileSize = 10; // MBs
                  return fileSizeCheck(
                    files.filesToAdd,
                    formFields.fireRisksCalculation,
                    maxAvailableFileSize
                  );
                },
              },
            },
            canDeleteFile: false,
            preview: false,
            input: (props) => <InputFileSingle {...props} accept={".png, .jpg, .jpeg, .pdf"} />,
          },
        ],
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.controlledPersonIndividCalcAvailability,
        label: "label:controlledPersonIndividCalcAvailability",
        type: "checkbox",
        rules: {},
        display: "flex",
        expended: [
          {
            name: formFields.individualizationOfTheControlledPersonCalculation,
            type: "file",
            rules: {
              validate: {
                type: (files) => {
                  const availableFileTypes = ["image/jpeg", "image/png", "application/pdf"];
                  return fileTypeCheck(files.filesToAdd, formFields.individualizationOfTheControlledPersonCalculation, availableFileTypes);
                },
                size: (files) => {
                  const maxAvailableFileSize = 10; // MBs
                  return fileSizeCheck(
                    files.filesToAdd,
                    formFields.individualizationOfTheControlledPersonCalculation,
                    maxAvailableFileSize
                  );
                },
              },
            },
            canDeleteFile: false,
            preview: false,
            input: (props) => <InputFileSingle {...props} accept={".png, .jpg, .jpeg, .pdf"} />,
          },
        ],
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.projectDocumentation,
        label: "label:projectDocumentation",
        type: "file",
        rules: {
          validate: {
            type: (files) => {
              const availableFileTypes = ["image/jpeg", "image/png", "application/pdf"];
              return fileTypeCheck(files.filesToAdd, formFields.projectDocumentation, availableFileTypes);
            },
            size: (files) => {
              const maxAvailableFileSize = 10; // MBs
              return fileSizeCheck(
                files.filesToAdd,
                formFields.projectDocumentation,
                maxAvailableFileSize
              );
            },
          },
        },
        canDeleteFile: true,
        input: (props) => (
          <Box>
            <Typography className="input-block-title">
              {t("label:projectDocumentation")}
            </Typography>
            <InputFileMultiple {...props} accept={".png, .jpg, .jpeg, .pdf"} />
          </Box>
        ),
      },
      {
        name: formFields.technicalDocumentation,
        label: "label:technicalDocumentation",
        type: "file",
        rules: {
          validate: {
            type: (files) => {
              const availableFileTypes = ["image/jpeg", "image/png", "application/pdf"];
              return fileTypeCheck(files.filesToAdd, formFields.technicalDocumentation, availableFileTypes);
            },
            size: (files) => {
              const maxAvailableFileSize = 10; // MBs
              return fileSizeCheck(
                files.filesToAdd,
                formFields.technicalDocumentation,
                maxAvailableFileSize
              );
            },
          },
        },
        canDeleteFile: true,
        input: (props) => (
          <Box>
            <Typography className="input-block-title">
              {t("label:technicalDocumentation")}
            </Typography>
            <InputFileMultiple {...props} accept={".png, .jpg, .jpeg, .pdf"} />
          </Box>
        ),
      },
      {
        name: formFields.fireCompartmentsCount,
        label: "label:fireCompartmentsCount",
        type: "number",
        rules: {
          integer: (v) => !!Number(v) && !/\s/g.test(v) && !/\./g.test(v),
        },
        flexBasis: "49%",
        input: (props) => (
          <Box>
            <InputText {...props} />
          </Box>
        ),
      },
    ],
  },
  {
    id: 3,
    name: formFields.buildingCharacteristics,
    label: "label:buildingCharacteristics",
    items: [
      {
        name: formFields.floorsCount,
        label: "label:floorsCount",
        type: "number",
        rules: {
          pattern: /^([0-9]+)$/,
          integer: (v) => !!Number(v) && !/\s/g.test(v) && !/\./g.test(v),
        },
        input: (props) => (
          <Box sx={{ maxWidth: "210px" }}>
            <InputText {...props} />
          </Box>
        ),
      },
      {
        name: formFields.height,
        label: "label:height",
        type: "number",
        rules: {
          fractional: (v) => !!Number(v) && !/\s/g.test(v),
        },
        step: "any",
        flexBasis: "49%",
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              <Typography className="adornment">м</Typography>
            </InputAdornment>
          ),
        },
        input: (props) => <InputText {...props} />,
      },
      {
        name: formFields.width,
        label: "label:width",
        type: "number",
        rules: {
          fractional: (v) => !!Number(v) && !/\s/g.test(v),
        },
        step: "any",
        flexBasis: "49%",
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              <Typography className="adornment">м</Typography>
            </InputAdornment>
          ),
        },
        input: (props) => <InputText {...props} />,
      },
      {
        name: formFields.length,
        label: "label:length",
        type: "number",
        rules: {
          fractional: (v) => !!Number(v) && !/\s/g.test(v),
        },
        step: "any",
        flexBasis: "49%",
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              <Typography className="adornment">м</Typography>
            </InputAdornment>
          ),
        },
        input: (props) => <InputText {...props} />,
      },
      {
        name: formFields.square,
        label: "label:square",
        type: "number",
        rules: {
          fractional: (v) => !!Number(v) && !/\s/g.test(v),
        },
        step: "any",
        flexBasis: "49%",
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              <Typography className="adornment">
                м<sup>2</sup>
              </Typography>
            </InputAdornment>
          ),
        },
        input: (props) => <InputText {...props} />,
      },
      {
        name: formFields.potentialRadiationHazardCategory,
        label: "label:potentialRadiationHazardCategory",
        type: "select-number",
        rules: {},
        flexBasis: "49%",
        options: potentialRadiationHazardOptions,
        input: (props) => <SelectSimple {...props} />,
      },
      {
        name: formFields.fireResistanceDegree,
        label: "label:fireResistanceDegree",
        type: "select-text",
        rules: {},
        flexBasis: "49%",
        options: fireResistanceDegreeOptions,
        input: (props) => <SelectSimple {...props} />,
      },
      {
        name: formFields.structuralFireHazardClass,
        label: "label:structuralFireHazardClass",
        type: "select-text",
        rules: {},
        flexBasis: "49%",
        options: structuralFireHazardClassOptions,
        input: (props) => <SelectSimple {...props} />,
      },
      {
        name: formFields.fireAndExplosionHazardOutdoorInstCategory,
        label: "label:fireAndExplosionHazardOutdoorInstCategory",
        type: "select-text",
        rules: {},
        flexBasis: "49%",
        options: fireAndExplosionHazard,
        input: (props) => <SelectSimple {...props} />,
      },
      {
        name: formFields.evacuationExitsCount,
        label: "label:evacuationExitsCount",
        type: "number",
        rules: {
          min: 1,
          pattern: /^([0-9]+)$/,
          integer: (v) => !!Number(v) && !/\s/g.test(v) && !/\./g.test(v),
        },
        flexBasis: "49%",
        input: (props) => <InputText {...props} />,
      },
      {
        name: formFields.simultaneousPeopleCount,
        label: "label:simultaneousPeopleCount",
        type: "number",
        rules: {
          min: 1,
          pattern: /^([0-9]+)$/,
          integer: (v) => !!Number(v) && !/\s/g.test(v) && !/\./g.test(v),
        },
        flexBasis: "49%",
        input: (props) => <InputText {...props} />,
      },
      {
        name: formFields.elevatorToTransportFirefightersAvailability,
        label: "label:elevatorToTransportFirefightersAvailability",
        type: "checkbox",
        rules: {},
        flexBasis: "49%",
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.stairwellsCount,
        label: "label:stairwellsCount",
        type: "number",
        rules: {
          min: 1,
          pattern: /^([0-9]+)$/,
          integer: (v) => !!Number(v) && !/\s/g.test(v) && !/\./g.test(v),
        },
        flexBasis: "49%",
        input: (props) => <InputText {...props} />,
      },
      {
        name: formFields.basementFloorAvailability,
        label: "label:basementFloorAvailability",
        type: "checkbox",
        rules: {},
        flexBasis: "49%",
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.internalFireWaterSupplyAvailability,
        label: "label:internalFireWaterSupplyAvailability",
        type: "checkbox",
        rules: {},
        flexBasis: "49%",
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.groundFloorAvailability,
        label: "label:groundFloorAvailability",
        type: "checkbox",
        rules: {},
        flexBasis: "49%",
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.fireHosesCount,
        label: "label:fireHosesCount",
        type: "number",
        rules: {
          pattern: /^([0-9]+)$/,
          integer: (v) => !!Number(v) && !/\s/g.test(v) && !/\./g.test(v),
        },
        flexBasis: "49%",
        input: (props) => <InputText {...props} />,
      },
      {
        name: formFields.fireWaterSupplyMaintenanceContractAvailability,
        label: "label:fireWaterSupplyMaintenanceContractAvailability",
        type: "checkbox",
        rules: {},
        flexBasis: "100%",
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.ventilationAvailability,
        label: "label:ventilationAvailability",
        type: "checkbox",
        rules: {},
        flexBasis: "100%",
        expended: [
          {
            name: formFields.ventilationType,
            label: "label:ventilationType",
            type: "select-text",
            rules: {},
            flexBasis: "49%",
            options: ventilationTypeOptions,
            input: (props) => <SelectSimple {...props} />,
          },
        ],
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.fuelForStandaloneHeatingType,
        label: "label:fuelForStandaloneHeatingType",
        type: "text",
        input: (props) => (
          <Box>
            <Typography className="input-block-title">
              {t("title:heating")}
            </Typography>
            <InputText {...props} />
          </Box>
        ),
      },
      {
        name: formFields.externalFireWaterSupplyAvailability,
        label: "label:externalFireWaterSupplyAvailability",
        type: "checkbox",
        rules: {},
        flexBasis: "49%",
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.specializedOrganizationProtectionInvolvement,
        label: "label:specializedOrganizationProtectionInvolvement",
        type: "checkbox",
        rules: {},
        flexBasis: "49%",
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.fireHydrantsCount,
        label: "label:fireHydrantsCount",
        type: "number",
        rules: {
          pattern: /^([0-9]+)$/,
          integer: (v) => !!Number(v) && !/\s/g.test(v) && !/\./g.test(v),
        },
        input: (props) => (
          <Box sx={{ maxWidth: "300px" }}>
            <InputText {...props} />
          </Box>
        ),
      },
    ],
  },
  {
    id: 4,
    name: formFields.facilitySystems,
    label: "label:facilitySystems",
    items: [
      {
        name: formFields.notifAndEvacControlSysAvailable,
        label: "label:notifAndEvacControlSysAvailable",
        type: "checkbox",
        rules: {},
        block: true,
        title: "title:SNME",
        expended: [
          {
            name: formFields.notifAndEvacControlSysEquipAmount,
            label: "label:total_number_equipment",
            rules: {
              pattern: /^([0-9]+)$/,
              integer: (v) => !!Number(v) && !/\s/g.test(v) && !/\./g.test(v),
            },
            type: "number",
            input: (props) => (
              <Box sx={{ maxWidth: "315px" }}>
                <InputText {...props} />
              </Box>
            ),
          },
          {
            name: formFields.notifAndEvacControlSysType,
            label: "label:notifAndEvacControlSysType",
            type: "select-number",
            rules: {},
            options: SNCEOptions,
            input: (props) => <SelectSimple {...props} />,
          },
          {
            name: formFields.NotificationAndEvacSysContract,
            label: "label:notifAndEvacControlSysMaintenanceContract",
            rules: {},
            type: "checkbox",
            input: (props) => <CheckboxSimple {...props} />,
          },
          {
            name: formFields.notifAndEvacControlSysInstallationDate,
            label: "label:notifAndEvacControlSysInstallationDate",
            type: "date",
            rules: {},
            flexBasis: "49%",
            sx: { width: "100%" },
            input: (props) => <DatePickerField {...props} />,
          },
          {
            name: formFields.notifAndEvacControlSysOverhaulDate,
            label: "label:notifAndEvacControlSysOverhaulDate",
            type: "date",
            rules: {},
            flexBasis: "49%",
            sx: { width: "100%" },
            input: (props) => <DatePickerField {...props} />,
          },
        ],
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.autoFireAlarmSysAvailable,
        label: "label:autoFireAlarmSysAvailable",
        type: "checkbox",
        rules: {},
        block: true,
        title: "title:AFA",
        expended: [
          {
            name: formFields.autoFireAlarmSysEquipAmount,
            label: "label:total_number_equipment",
            rules: {
              pattern: /^([0-9]+)$/,
              integer: (v) => !!Number(v) && !/\s/g.test(v) && !/\./g.test(v),
            },
            type: "number",
            input: (props) => (
              <Box sx={{ maxWidth: "315px" }}>
                <InputText {...props} />
              </Box>
            ),
          },
          {
            name: formFields.autoFireAlarmSysType,
            label: "label:autoFireAlarmSysType",
            type: "select-text",
            rules: {},
            options: AFASOptions,
            input: (props) => <SelectSimple {...props} />,
          },
          {
            name: formFields.duplicatingToTheRemoteControl,
            label: "label:duplicatingToTheRemoteControl",
            rules: {},
            type: "checkbox",
            flexBasis: "49%",
            input: (props) => <CheckboxSimple {...props} />,
          },
          {
            name: formFields.autoFireAlarmSysMaintenanceContract,
            label: "label:autoFireAlarmSysMaintenanceContract",
            rules: {},
            type: "checkbox",
            flexBasis: "49%",
            input: (props) => <CheckboxSimple {...props} />,
          },
          {
            name: formFields.autoFireAlarmSysInstallationDate,
            label: "label:autoFireAlarmSysInstallationDate",
            type: "date",
            rules: {},
            flexBasis: "49%",
            sx: { width: "100%" },
            input: (props) => <DatePickerField {...props} />,
          },
          {
            name: formFields.autoFireAlarmSysOverhaulDate,
            label: "label:autoFireAlarmSysOverhaulDate",
            type: "date",
            rules: {},
            flexBasis: "49%",
            sx: { width: "100%" },
            input: (props) => <DatePickerField {...props} />,
          },
          {
            name: formFields.detectorsType,
            label: "label:detectorsType",
            type: "select-text",
            rules: {},
            flexBasis: "49%",
            options: typesAlarmOptions,
            input: (props) => <SelectSimple {...props} />,
          },
        ],
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.autoFireSuppressionSysAvailable,
        label: "label:autoFireSuppressionSysAvailable",
        type: "checkbox",
        rules: {},
        block: true,
        title: "title:AFSS",
        expended: [
          {
            name: formFields.autoFireSuppressionSysEquipAmount,
            label: "label:total_number_equipment",
            rules: {
              pattern: /^([0-9]+)$/,
              integer: (v) => !!Number(v) && !/\s/g.test(v) && !/\./g.test(v),
            },
            type: "number",
            input: (props) => (
              <Box sx={{ maxWidth: "315px" }}>
                <InputText {...props} />
              </Box>
            ),
          },
          {
            name: formFields.autoFireSuppressionSysType,
            label: "label:autoFireSuppressionSysType",
            type: "select-text",
            rules: {},
            flexBasis: "49%",
            options: AFSSTOptions,
            input: (props) => <SelectSimple {...props} />,
          },
          {
            name: formFields.autoFireSuppressionSysMaintenanceContract,
            label: "label:autoFireSuppressionSysMaintenanceContract",
            rules: {},
            type: "checkbox",
            flexBasis: "49%",
            input: (props) => <CheckboxSimple {...props} />,
          },
          {
            name: formFields.autoFireSuppressionSysInstallationDate,
            label: "label:autoFireSuppressionSysInstallationDate",
            type: "date",
            rules: {},
            flexBasis: "49%",
            sx: { width: "100%" },
            input: (props) => <DatePickerField {...props} />,
          },
          {
            name: formFields.autoFireSuppressionSysOverhaulDate,
            label: "label:autoFireSuppressionSysOverhaulDate",
            type: "date",
            rules: {},
            flexBasis: "49%",
            sx: { width: "100%" },
            input: (props) => <DatePickerField {...props} />,
          },
        ],
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.electricityAvailable,
        label: "label:electricityAvailable",
        type: "checkbox",
        rules: {},
        block: true,
        title: "title:powerSupply",
        expended: [
          {
            name: formFields.voltage,
            label: "label:voltage",
            type: "select-number",
            rules: {},
            options: voltageOptions,
            input: (props) => (
              <Box sx={{ width: "49%" }}>
                <SelectSimple {...props} />
              </Box>
            ),
          },
          {
            name: formFields.electricityInstallationDate,
            label: "label:electricityInstallationDate",
            type: "date",
            rules: {},
            flexBasis: "49%",
            sx: { width: "100%" },
            input: (props) => <DatePickerField {...props} />,
          },
          {
            name: formFields.electricityOverhaulDate,
            label: "label:electricityOverhaulDate",
            type: "date",
            rules: {},
            flexBasis: "49%",
            sx: { width: "100%" },
            input: (props) => <DatePickerField {...props} />,
          },
          {
            name: formFields.wiringType,
            label: "label:wiringType",
            type: "select-text",
            rules: {},
            options: typeWiringOptions,
            input: (props) => (
              <Box sx={{ width: "49%" }}>
                <SelectSimple {...props} />
              </Box>
            ),
          },
        ],
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.gasAvailable,
        label: "label:gasAvailable",
        type: "checkbox",
        rules: {},
        block: true,
        title: "title:gas",
        expended: [
          {
            name: formFields.gasInstallationDate,
            label: "label:gasInstallationDate",
            type: "date",
            rules: {},
            flexBasis: "49%",
            sx: { width: "100%" },
            input: (props) => <DatePickerField {...props} />,
          },
          {
            name: formFields.gasOverhaulDate,
            label: "label:gasOverhaulDate",
            type: "date",
            rules: {},
            flexBasis: "49%",
            sx: { width: "100%" },
            input: (props) => <DatePickerField {...props} />,
          },
        ],
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.fullTimeStayAvailability,
        label: "label:fullTimeStayAvailability",
        type: "checkbox",
        input: (props) => (
          <Box sx={{ paddingLeft: "16px" }}>
            <CheckboxSimple {...props} />
          </Box>
        ),
      },
      {
        name: formFields.newYearMassAvailabilityOfChildren,
        label: "label:newYearMassAvailabilityOfChildren",
        type: "checkbox",
        input: (props) => (
          <Box sx={{ paddingLeft: "16px" }}>
            <CheckboxSimple {...props} />
          </Box>
        ),
      },
      {
        name: formFields.storesAndSellsPyrotechnicProducts,
        label: "label:storesAndSellsPyrotechnicProducts",
        type: "checkbox",
        input: (props) => (
          <Box sx={{ paddingLeft: "16px" }}>
            <CheckboxSimple {...props} />
          </Box>
        ),
      },
      {
        name: formFields.involvedInElections,
        label: "label:involvedInElections",
        type: "checkbox",
        input: (props) => (
          <Box sx={{ paddingLeft: "16px" }}>
            <CheckboxSimple {...props} />
          </Box>
        ),
      },
      {
        name: formFields.particularlyHazardousAndTechnicallyComplex,
        label: "label:particularlyHazardousAndTechnicallyComplex",
        type: "checkbox",
        input: (props) => (
          <Box sx={{ paddingLeft: "16px" }}>
            <CheckboxSimple {...props} />
          </Box>
        ),
      },
      {
        name: formFields.criticalToCountriesNationalSecurity,
        label: "label:criticalToCountriesNationalSecurity",
        type: "checkbox",
        input: (props) => (
          <Box sx={{ paddingLeft: "16px" }}>
            <CheckboxSimple {...props} />
          </Box>
        ),
      },
      {
        name: formFields.hazardousProductionFacilitiesAvailability,
        label: "label:hazardousProductionFacilitiesAvailability",
        type: "checkbox",
        input: (props) => (
          <Box sx={{ paddingLeft: "16px" }}>
            <CheckboxSimple {...props} />
          </Box>
        ),
      },
      {
        name: formFields.bookRepositoryOrArchiveOfFederalImportance,
        label: "label:bookRepositoryOrArchiveOfFederalImportance",
        type: "checkbox",
        input: (props) => (
          <Box sx={{ paddingLeft: "16px" }}>
            <CheckboxSimple {...props} />
          </Box>
        ),
      },
    ],
  },
];
