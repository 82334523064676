// libs imports
import React from "react";
import InputText from "../../../components/InputText";
import ReadOnlyPersonalData from "./ReadOnlyPersonalData";
import ProfileContactData from "../ProfileContactData";
import TelegramNotificationsAvailability from "./Components";
import InputHandleEditAvailability from "../../../components/InputHandleEditAvailability/InputHandleEditAvailability";
import { EmployeeProfile } from "./EmployeeProfile";
import { ReadOnlyEmployeePersonalData } from "./ReadOnlyEmployeePersonalData";
import { ReadOnlyEmployeeContactInfo } from "./ReadOnlyEmployeeContactInfo";

export const formFields = {
  id: "id",
  firstName: "firstName",
  surname: "surname",
  lastName: "lastName",
  email: "email",
  phone: "phone",
  telegramNotificationsAvailability: "telegramNotificationsAvailability",
  readOnlyPersonalData: "readOnlyPersonalData",
};

export const telegramNotificationsAvailableRole = "Администратор системы";

export const getDefaultValues = (profileData) => {
  const {
    id,
    firstName,
    surname,
    lastName,
    email,
    phone,
    avatarLink,
    telegramNotificationsAvailability = false,
  } = profileData;
  return {
    id,
    firstName,
    surname,
    lastName,
    email,
    phone,
    avatarLink: {
      filesPrev: avatarLink ? [avatarLink] : [],
      filesToAdd: [],
      filesToDelete: [],
    },
    telegramNotificationsAvailability,
  };
};

export const ProfileMainDataFormFormConfig = [
  {
    id: 0,
    CustomFormItem: ProfileContactData,
  },
  {
    id: 1,
    title: "label:personalData",
    block: true,
    expended: [
      {
        name: formFields.lastName,
        label: "label:lastName",
        type: "text",
        rules: {
          required: true,
        },
        flexBasis: "33%",
        input: (props) => (
          <InputHandleEditAvailability
            disabled={true}
            {...props}
            Component={InputText}
          />
        ),
      },
      {
        name: formFields.firstName,
        label: "label:firstName",
        type: "text",
        rules: {
          required: true,
        },
        flexBasis: "33%",
        input: (props) => (
          <InputHandleEditAvailability {...props} Component={InputText} />
        ),
      },
      {
        name: formFields.surname,
        label: "label:surname",
        type: "text",
        rules: {},
        flexBasis: "33%",
        input: (props) => (
          <InputHandleEditAvailability {...props} Component={InputText} />
        ),
      },
      {
        items: [],
        name: formFields.readOnlyPersonalData,
        Component: ReadOnlyPersonalData,
      },
    ],
  },
  {
    id: 3,
    name: formFields.telegramNotificationsAvailability,
    label: "label:telegramNotificationsAvailability",
    type: "checkbox",
    rules: {},
    input: (props) => <TelegramNotificationsAvailability {...props} />,
  },
];

export const ProfileEmployeeFormFormConfig = [
  {
    id: 0,
    mode: "employee",
    CustomFormItem: EmployeeProfile,
  },
  {
    id: 1,
    title: "label:personalData",
    block: true,
    expended: [
      {
        items: [],
        name: formFields.readOnlyPersonalData,
        Component: ReadOnlyEmployeePersonalData,
      },
    ],
  },
  {
    id: 2,
    title: "label:contactInformation",
    block: true,
    expended: [
      {
        items: [],
        name: formFields.readOnlyPersonalData,
        Component: ReadOnlyEmployeeContactInfo,
      },
    ],
  },
  {
    id: 3,
    name: formFields.telegramNotificationsAvailability,
    label: "label:telegramNotificationsAvailability",
    type: "checkbox",
    rules: {},
    input: (props) => <TelegramNotificationsAvailability {...props} />,
  },
];
