import { t } from "i18next";
import OrganizationIcon from "./views/components/icons/OrganizationIcon";
import ObjectsIcon from "./views/components/icons/ObjectsIcon";
import EquipmentIcon from "./views/components/icons/EquipmentIcon";
import EmployeesIcon from "./views/components/icons/EmployeesIcon";
import TasksIcon from "./views/components/icons/TasksIcon";
import ReglamentIcon from "./views/components/icons/ReglamentIcon";
import MagazinesIcon from "./views/components/icons/MagazinesIcon";
import AnalyticsIcon from "./views/components/icons/AnalyticsIcon";
import GostsIcon from "./views/components/icons/GostsIcon";
import IntegrationIcon from "./views/components/icons/IntegrationIcon";
import BillingIcon from "./views/components/icons/BillingIcon";
import { SettingsIcon } from "./views/components/icons/SettingsIcon";

import ResponsibleIcon from "./views/components/icons/ResponsibleIcon";
import {
  ANALYTICS,
  DIGITIZATION,
  MAGAZINES,
  OBJECTS,
  ORGANIZATIONS,
  RESPONSIBLE,
  EMPLOYEES,
  TASKS,
  ACTS
} from "./constants";
import { PATHS } from "./constants";
import { TimeTableIcon } from "./views/components/icons/TimeTableIcon";
import PlumeIcon from "./views/components/icons/PlumeIcon";
import { DocumentsIcon } from "./views/components/icons/DocumentsIcon";
import { ConsolesIcon } from "./views/components/icons/ConsolesIcon";

export const BIG_SCREEN = 800;

export const sideBarOptions = [
  {
    id: 2,
    title: "Объекты",
    icon: ObjectsIcon,
    expanded: true,
    role: "",
    rootPath: OBJECTS,
    path: PATHS.LIST_OBJECTS,
    level: 1,
    subItems: [
      {
        id: 21,
        title: "Список объектов",
        icon: null,
        expanded: false,
        role: "",
        path: PATHS.LIST_OBJECTS,
        level: 2,
      },
      {
        id: 22,
        title: "Объекты на карте",
        icon: null,
        expanded: false,
        role: "",
        path: PATHS.OBJECTS_MAP,
        level: 2,
      },
    ],
  },
  {
    id: 4,
    title: "Оборудование",
    icon: EquipmentIcon,
    expanded: false,
    role: "",
    path: PATHS.EQUIPMENT,
    level: 1,
    subItems: [],
  },

  {
    id: 8,
    title: "Журналы",
    icon: MagazinesIcon,
    expanded: true,
    role: "",
    rootPath: MAGAZINES,
    path: PATHS.MAGAZINES_SYSTEMS,
    level: 1,
    subItems: [
      {
        id: 81,
        title: "Журнал эксплуатации систем противопожарной защиты",
        icon: null,
        expanded: false,
        role: "",
        path: PATHS.MAGAZINES_SYSTEMS,
        level: 2,
      },
      {
        id: 82,
        title: "Журнал учета технических средств",
        icon: null,
        expanded: false,
        role: "",
        path: PATHS.MAGAZINES_TECH,
        level: 2,
      },
      {
        id: 83,
        title: "Журнал регистрации извещений от автоматики",
        icon: null,
        expanded: false,
        role: "",
        path: PATHS.MAGAZINES_NOTIFICATIONS,
        level: 2,
      },
      {
        id: 84,
        title: "Журнал учета назначений",
        icon: null,
        expanded: false,
        role: "",
        path: PATHS.MAGAZINES_ACCOUNTING,
        level: 2,
      },
      {
        id: 85,
        title: "Журнал событий",
        icon: null,
        expanded: false,
        role: "",
        path: PATHS.MAGAZINES_EVENTS,
        level: 2,
      },
    ],
  },
  {
    id: 10,
    title: "ГОСТы",
    icon: GostsIcon,
    expanded: false,
    role: "",
    path: PATHS.GOSTS,
    level: 1,
    subItems: [],
  },
  {
    id: 11,
    title: "Интеграции",
    icon: IntegrationIcon,
    expanded: false,
    role: "",
    path: PATHS.INTEGRATION,
    level: 1,
    subItems: [],
  },
  {
    id: 12,
    title: "Биллинг",
    icon: BillingIcon,
    expanded: false,
    role: "",
    path: PATHS.BILLING,
    level: 1,
    subItems: [],
  },
  {
    id: 13,
    title: "Настройки",
    icon: SettingsIcon,
    expanded: false,
    role: "",
    path: PATHS.SETTINGS,
    level: 1,
    subItems: [],
  },
];

export const sideBarOptionsNewVersion = [
  {
    id: 3,
    name: "users",
    title: t("sidebar:employees"),
    icon: EmployeesIcon,
    expanded: true,
    role: "",
    rootPath: EMPLOYEES,
    path: PATHS.LIST_EMPLOYEES,
    level: 1,
    subItems: [
      {
        id: 21,
        title: t("sidebar:list_employees"),
        icon: null,
        expanded: false,
        role: "",
        path: PATHS.LIST_EMPLOYEES,
        level: 2,
      },
      {
        id: 22,
        title: t("sidebar:add_employee"),
        icon: null,
        expanded: false,
        role: "",
        path: PATHS.ADD_EMPLOYEE,
        level: 2,
      },
    ],
  },

  {
    id: 2,
    name: "facilities",
    title: t("sidebar:objects"),
    icon: ObjectsIcon,
    expanded: false,
    role: "",
    path: PATHS.LIST_OBJECTS,
    level: 1,
    subItems: [],
  },
  // {
  //   id: 3,
  //   title: "Подрядчики",
  //   icon: ResponsibleIcon,
  //   expanded: true,
  //   role: "",
  //   rootPath: RESPONSIBLE,
  //   path: PATHS.RESPONSIBLE_ORGANIZATIONS,
  //   level: 1,
  //   subItems: [
  //     {
  //       id: 31,
  //       title: "Список подрядчиков",
  //       icon: null,
  //       expanded: false,
  //       role: "",
  //       path: PATHS.RESPONSIBLE_ORGANIZATIONS,
  //       level: 2,
  //     },
  //     {
  //       id: 32,
  //       title: "Исполнители",
  //       icon: null,
  //       expanded: false,
  //       role: "",
  //       path: PATHS.PERFORMERS,
  //       level: 2,
  //     },
  //   ],
  // },
  {
    id: 6,
    name: "logs",
    title: t("sidebar:magazines"),
    icon: MagazinesIcon,
    expanded: false,
    role: "",
    path: PATHS.MAGAZINES_EVENTS,
    level: 1,
    subItems: [],
  },
  {
    id: 17,
    name: "control-console",
    title: t("sidebar:controllers"),
    icon: ConsolesIcon,
    expanded: false,
    role: "",
    path: PATHS.CONTROLLERS,
    level: 1,
    subItems: [],
  },
];
