import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { useAction } from "../../../../hooks/actions";
import FormItem from "../../../components/FormItem";
import { WarningValidationForm } from "../../../components/Notifications";
import { basicInformationConfig, formFields } from "../form-config";
import { DEFAULT_VALUE } from "../constants";
import { useAddNewUserMutation } from "../../../../middleware/usersApi";
import { formatPhoneNumber } from "../helpers";
import { PATHS } from "../../../../constants";
import { useAppSelector } from "../../../../hooks/redux";
import { checkboxValues } from "./constants";
import { useGetAllOrganizationsSelectQuery } from "../../../../middleware/apiOrganizations";
import { useLazyGetEquipmentSystemsQuery } from "../../../../middleware/equipmentsApi";

export const AddEmployee = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { token } = useAppSelector((state) => state.users);

  const { setIsErrorSubmit, setIsSuccessSubmit } = useAction();

  const [addEmployee, { error: errorAdd, isSuccess: isSuccessAdd }] =
    useAddNewUserMutation();
  const { data: organizationsSelect = [] } =
    useGetAllOrganizationsSelectQuery();

  const [getequipmentSelectElements, { error: errorGet, isSuccess: isSuccessGet }] =
    useLazyGetEquipmentSystemsQuery();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    mode: "onChange",
    defaultValues: DEFAULT_VALUE,
  });
  const isWarningValidation = !!Object.keys(errors).length;
  const error = errorAdd;
  const isSuccess = isSuccessAdd;

  const onSubmit = async (data) => {
    console.log("data",data)
    const {
      fullName,
      ...rest
    } = data || {};
    
    const fio = fullName.split(" ");
    const firstName = fio[1] || "";
    const surname = fio[2] || "";
    const lastName = fio[0] || "";

    const requestData = {
      phoneNumber: formatPhoneNumber(data.phoneNumber),
      email:data.email,
      login:data.login,
      password:data.password,
      PasswordRepeat: data.password,
      firstName: firstName,
      roleId:data.roleId,
      surname: surname,
      lastName: lastName,
    };

    addEmployee({ token, data: requestData }).then((result) => {
      if ("error" in result) {
        return;
      }
      navigate(PATHS.LIST_EMPLOYEES);
    });
  };

  const handleCancel = () => navigate(-1);

  useEffect(() => {
    setIsErrorSubmit(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);
  return (
    <Box className="template_page employee">
      <Box className="template_page-header">
        <Typography className="template_page-header-title">
          {t("title_page:add_employee")}
        </Typography>
        <Box className="template_page-header-buttons">
          <Button className="btn btn-outlined-grey" onClick={handleCancel}>
            {t("button:comeback")}
          </Button>
          <Button
            form="myform"
            className="btn btn-primary-fill"
            type="submit"
            disabled={isSubmitSuccessful && !error}
          >
            {t("button:save")}
          </Button>
        </Box>
      </Box>
      <form
        className="scrolled-form"
        id="myform"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box>
          <Box className="basic_form">
            {basicInformationConfig.map(({ id, name, label, rules, input, ...rest }) => (
              <FormItem
                key={id}
                control={control}
                errors={errors}
                name={name}
                label={label}
                input={input}
                /* display={
                   !isContactPerson && name === formFields.organizationWorkingFor
                     ? "none"
                     : "block"
                 }*/
                rules={{}
                  // {required: isContactPerson }
                  /* name === formFields.organizationWorkingFor
                     ? { required: isContactPerson }
                     : rules*/
                }
                {...rest}
              />
            ))}
          </Box>
        </Box>
      </form>

      {isWarningValidation && (
        <WarningValidationForm isWarning={isWarningValidation} />
      )}
    </Box>
  );
};
