export const getBgColor = (option) => (row, index) => {
  const { messageClassTypeId, triggerCause, messageCodeTypeColor } = row || {};
  if (messageCodeTypeColor != "") return messageCodeTypeColor;
  //if (triggerCause === "ТО") return "var(--bgGrey)";

  const colorItem =
    messageClassTypeId &&
    option.find((item) => item.value === messageClassTypeId);

  return colorItem
    ? colorItem?.color
    : index % 2
    ? "var(--bgTableRow)"
    : "var(--bgWhite)";
};
export const getBorderColor = (option) => (row, index) => {
  const { messageCodeTypeBorderColor} = row || {};
  console.log("messageCodeTypeBorderColor",messageCodeTypeBorderColor)
  if (messageCodeTypeBorderColor != "") return messageCodeTypeBorderColor;
 
};
