import React from "react";
import { t } from "i18next";
import { Typography, Box, Button } from "@mui/material";
import { AddressInputInbuilt } from "../../components/AddressInputInbuilt";
import { formFields } from "./form-config";
import { SimpleHeader } from "../Controllers/Components";
import { SearchInbuilt } from "../../components/SearchInbuilt";
import FormItem from "../../components/FormItem";
import DatePickerInbuild from "../../components/DateTimePickerInbuild";
import { SimpleHHeader } from "./Components";
import SelectSimpleInbuilt from "../../components/SelectSimpleInbuilt";
import { eventsOptions, bgColors } from "./options";
import { SelectSimpleObject } from "./Components"
import {
  DateFormatCell,
  ClickableJustFullNameCell,
  SimpleTextCell,
  ClickableTaskTextCell,
  ClickableEquipmentTextCell,
  JustFullNameCell,
} from "./Components";

export const getColumns = ({
  control,
  searchAddress,
  setsearchAddress,
  messageClassSearch,
  setMessageClassSearch,
  searchController,
  setsearchController,
  roomPlume,
  setroomPlume,
  triggerCause,
  settriggerCause,
  executiveUser,
  setexecutiveUser,
  responsibleUser,
  setresponsibleUser,
  columnsFromStorage,
  handleOpenEquipment,
}) => {
  return [
    {
      id: formFields.messageTime,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:dateAndTime"),
      size: "130px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.reactionDateTime],
      RowCell: (props) => <DateFormatCell {...props} />,
      HeaderCell: () => (
        <>
          <SimpleHHeader name={t("tableHeader:dateTime")} />

          <FormItem
            control={control}
            placeholder={"с"}
            name={`${formFields.searchParams}.ReactionDateRange.MinValue`}
            sx={{ padding: "1px 0" }}
            input={(props) => (
              <DatePickerInbuild
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                {...props}
              />
            )}
          />
          <FormItem
            control={control}
            placeholder={"по"}
            name={`${formFields.searchParams}.ReactionDateRange.MaxValue`}
            sx={{ padding: "1px 0" }}
            input={(props) => (
              <DatePickerInbuild
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                {...props}
              />
            )}
          />
        </>
      )
    },
    {
      id: formFields.protectedObjectName,
      numeric: false,
      enableSorting: false,
      size: "130px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.facility],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <>
          <SimpleHeader name={t("tableHeader:facilityName")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.FacilityId}`}
            sx={{ padding: "12px 0" }}
            defaultValue={"все"}
            input={(props) => (
              <SelectSimpleObject
                {...props}
                className="select_in_built small-light"
                sx={{ width: "200px", backgroundColor: "var(--bgLightGrey)" }}
              //bgcolor={}

              />
              /*<SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={facilityName}
                changeSearchValue={setFacilityName}
                {...props}
              />*/
            )}
          />
        </>
      ),
    },
    {
      id: "address",
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:address"),
      isVisible: true,//columnsFromStorage["address"],
      clickable: false,
      HeaderCell: () => (
        <Box sx={{ width: "250px" }}>
          <SimpleHeader name={t("tableHeader:address")} />

          <FormItem
            control={control}
            name={`${formFields.searchParams}.address`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <AddressInputInbuilt
                className="select_in_built small-light"
                value={searchAddress}
                onChange={setsearchAddress}

                bgcolor={"var(--bgLightGrey)"}
              />
            )}
          />
        </Box>

      ),
    },
    {
      id: "messageCodeName",
      numeric: false,
      enableSorting: false,
      size: "200px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.messageClass],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <>
          <SimpleHeader name={t("tableHeader:event_class")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.MessageClass`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={messageClassSearch}
                changeSearchValue={setMessageClassSearch}
                {...props}
              />

            )}
          />
        </>

      ),
    },
    {
      id: formFields.plume,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:roomAddressPlume"),
      size: "220px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.roomAddressPlume],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <>
          <SimpleHeader name={t("tableHeader:roomAddressPlume")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.RoomPlume`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={roomPlume}
                changeSearchValue={setroomPlume}
                {...props}
              />

            )}
          />
        </>

      ),
    },
    /*{
      id: formFields.equipmentName,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:equipment"),
      size: "170px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.equipmentName],
      RowCell: (props) => <Button
        className="table-row-cell-link"
        sx={{ verticalAlign: "initial",textAlign: "left" }}
        onClick={() => handleOpenEquipment(props.row.equipmentId)}
      >
        {props.row.equipmentName}
      </Button>,
      HeaderCell: () => (
        <Box sx={{ width: "110px" }}>
          <SimpleHeader name={t("tableHeader:equipment")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.EquipmentNameAndNumber`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={equipName}
                changeSearchValue={setequipName}
                {...props}
              />

            )}
          />
        </Box>

      ),
    },*/
    {
      id: formFields.triggerCause,
      numeric: false,
      enableSorting: false,
      size: "200px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.triggerCause],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <>
          <SimpleHeader name={t("tableHeader:triggerCause")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.TriggerCause`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={triggerCause}
                changeSearchValue={settriggerCause}
                {...props}
              />

            )}
          />
        </>

      ),
    },
    {
      id: "controlConsoleName",
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:controller"),
      size: "150px",
      clickable: false,
      isVisible: columnsFromStorage["controlConsoleName"],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <>
          <SimpleHeader name={t("tableHeader:controller")} />

          <FormItem
            control={control}
            name={`${formFields.searchParams}.ControlConsoleName`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={searchController}
                changeSearchValue={setsearchController}
                {...props}
              />

            )}
          />
        </>

      ),
    },
    {
      id: formFields.responsible,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:r_organization"),
      size: "150px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.responsible],
      RowCell: (props) => <JustFullNameCell {...props} />,
      HeaderCell: () => (
        <>
          <SimpleHeader name={t("tableHeader:r_organization")} />

          <FormItem
            control={control}
            name={`${formFields.searchParams}.ResponsibleCustomerUser`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={responsibleUser}
                changeSearchValue={setresponsibleUser}
                {...props}
              />

            )}
          />
        </>

      ),
    },
    {
      id: formFields.executive,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:executive"),
      size: "150px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.executive],
      RowCell: (props) => <ClickableJustFullNameCell {...props} />,
      HeaderCell: () => (
        <>
          <SimpleHeader name={t("tableHeader:executive")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.ExecutiveUser`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={executiveUser}
                changeSearchValue={setexecutiveUser}
                {...props}
              />

            )}
          />
        </>

      ),
    },
  ].filter(({ isVisible }) => isVisible);
};
