import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Box, Button, Checkbox, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";

import { formConfigFirstStep } from "../form-config";
import { useLazyCheckUsersLoginQuery } from "../../../../middleware/usersApi";

import FormItem from "../../../components/FormItem";
import { Dogovor1 } from "./Dogovor1";
import { Dogovor2 } from "./Dogovor2";
import { Dogovor3 } from "./Dogovor3";

const FirstStep = ({ setAuthStep }) => {
  const { t } = useTranslation();

  const [checkUserLogin] = useLazyCheckUsersLoginQuery();
  const [openDogovor1, setOpenDogovor1] = useState(false);
  const [openDogovor2, setOpenDogovor2] = useState(false);
  const [openDogovor3, setOpenDogovor3] = useState(false);

  const {
    control,
    handleSubmit,
    setError,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const checkedConfirm = watch("confirm");

  const onSubmit = async (data) => {
    const response = await checkUserLogin(data);
    if (response.error?.status === 404) {
      setError("login", { type: "custom", message: "user_not_found" });
      return;
    }
    setAuthStep(1);
  };

  return (
    <>
      <form id="myform" className="object-form first_step" onSubmit={handleSubmit(onSubmit)}>
        {formConfigFirstStep.map(({ name, label, input, ...rest }) => (
          <FormItem
            key={name}
            control={control}
            errors={errors}
            name={name}
            label={label}
            input={input}
            {...rest}
          />
        ))}
        <Button
          fullWidth
          className="btn btn-primary-fill first_step-btn_next"
          variant="contained"
          type="submit"
          disabled={!checkedConfirm}
        >
          {t("button:continue")}
        </Button>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "15px",
          }}
        >
          <Button
            className="btn btn-link"
            onClick={setOpenDogovor1.bind(null, true)}
            sx={{ textAlign: "start", lineHeight: "20px" }}
          >
            {t("button:agree_processing_data")}
          </Button>
          <Button
            className="btn btn-link"
            onClick={setOpenDogovor2.bind(null, true)}
            sx={{ textAlign: "start", lineHeight: "20px" }}
          >
            {t("button:privacy_policy")}
          </Button>
          <Button
            className="btn btn-link"
            onClick={setOpenDogovor3.bind(null, true)}
            sx={{ textAlign: "start", lineHeight: "20px" }}
          >
            {t("button:agree_use_app")}
          </Button>
        </Box>

        <Box className="confirm_conditions">
          <Controller
            name="confirm"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} />}
                label={t("label:confirm_conditions")}
              />
            )}
          />
        </Box>
      </form>
      {openDogovor1 && (
        <Dogovor1 open={openDogovor1} onClose={setOpenDogovor1.bind(null, false)} />
      )}
      {openDogovor2 && (
        <Dogovor2 open={openDogovor2} onClose={setOpenDogovor2.bind(null, false)} />
      )}
      {openDogovor3 && (
        <Dogovor3 open={openDogovor3} onClose={setOpenDogovor3.bind(null, false)} />
      )}
    </>
  );
};

export default FirstStep;
