import {t} from "i18next";

export const getUserJobTitlesOptions = (data) => {
  return data?.map(({ id, name }) => ({
    value: id,
    name: name,
  }));
};

export const getAllOrganization = (data) => {
  return [
    {
      id: 1,
      name: t("label:all_organizations"),
      items: data,
      open: false,
      checked: false,
    }
  ];
};
export const formatPhoneNumber = (phoneNumber) => {
  let digits = phoneNumber.replace(/\D/g, "");

  return digits;
};

export const getEquipmentOptions = (data) => {
  return data?.map(({ id, name }) => ({
    id: id,
    name: name,
  }));
};
export const getUserRoleOptions = (data) => {
  return data?.map(({ id, name }) => ({
    value: id,
    name: name,
  }));
};
export const selectOrganization = (data) => {
  return data?.map(({ id, name }) => ({
    value: id,
    name: name,
  }));
};