import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Box, Button, Typography, Tooltip } from "@mui/material";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { useForm, useWatch } from "react-hook-form";
import { PATHS, ROLES, globalFields, ROLE_TYPES } from "../../../constants";
import { useAppSelector } from "../../../hooks/redux";
import { useAction } from "../../../hooks/actions";
import { useLazyGetAllFacilitiesQuery } from "../../../middleware/facilitiesApi";
import { getDataToChart } from "../../../helpers/helper";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import TableBasic from "../../components/TableBasic";
import ObjectDialog from "./ObjectDialog";
import EditButton from "../../components/EditButton";
import EquipmentRegistry from "./EquipmentRegistry";
import { RatingBox } from '../ObjectsOnMap/RatingBox';
import { ModalSelectColumnsServer } from "../../components/Modals/ModalSelectColumnsServer";
import { useGetUserGridSettingQuery, useLazyGetUserGridSettingQuery } from "../../../middleware/usersApi";
import {
  allColumns,
  getConfigFormModalSelect,
} from "./form-config";
import { getColumns } from "./columns";

const ObjectsList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [getParams, setSearchParams] = useSearchParams();
  const ShowObjectInFireAlarmsPerActiveSession = getParams.has("ShowObjectInFireAlarmsPerActiveSession") ? getParams.get("ShowObjectInFireAlarmsPerActiveSession") : null;
  const { control, setValue, watch, handleSubmit, resetField } = useForm();
  const { roleId } = useAppSelector((state) => state.users.info);
  const roleUser = ROLES[roleId];
  const { allFacilities } = useAppSelector((state) => state.facilities);
  const { setIsErrorRequest, setSortParams } = useAction();
  const [openObjectDialog, setOpenObjectDialog] = useState(false);
  const [selectedObjectId, setSelectedObjectId] = useState(null);
  const [selectedObjectName, setselectedObjectName] = useState(null);
  const [pageNumber, changePageNumber] = useState(0);
  const [searchValue, changeSearchValue] = useState("");
  const [equipmentRegistryDialog, setEquipmentRegistryDialog] = useState(false);
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [openSettingsModal, setopenSettingsModal] = useState(false);
  const [searchAddress, setsearchAddress] = useState("");
  const [phone, setphone] = useState("");
  const [intNumber, setintNumber] = useState("");
  const [searchObject, setsearchObject] = useState("");

  const {
    data: gridcolumns,
    error: errorgridColumns,
    isLoading: isLoadingerrorColumns,
  } = useGetUserGridSettingQuery({ gridSettingName: "ProtectionObjectList" });

  const [getUserGrid, { error: errorgrid, refetch: refetchUserGrid /* isLoading */ }] =
    useLazyGetUserGridSettingQuery();

  const handleOpenObjectDialogState = (id) => {
    if (!openObjectDialog) {
      setOpenObjectDialog(true);
      setSelectedObjectId(id);
      return;
    }
    setOpenObjectDialog(false);
    setSelectedObjectId(null);
  };
  const handleOpenSettingsDialog = (id, name) => {
    console.log("openSettingsModal", openSettingsModal)
    if (!openSettingsModal) {
      setopenSettingsModal(true);
      setSelectedObjectId(id);
      setselectedObjectName(name)
      return;
    }
    setopenSettingsModal(false);
    setSelectedObjectId(null);
    setselectedObjectName(null)
  };

  const handleEquipmentRegistryDialog = (id) => {
    if (!equipmentRegistryDialog) {
      setEquipmentRegistryDialog(true);
      setSelectedObjectId(id);
      return;
    }
    setEquipmentRegistryDialog(false);
    setSelectedObjectId(null);
  };

  const [getFacilitiesTable, { error, isLoading }] =
    useLazyGetAllFacilitiesQuery();

  const columnsFromStorage = (() => {
    const storageData =
      gridcolumns?.columnSettings || [];
    return storageData?.reduce(
      (acc, { name, visible }) => ({ ...acc, [name]: visible }),
      {}
    );
  })();

  const columns = useMemo(
    () =>
      getColumns({
        control,
        columnsFromStorage,
        searchAddress,
        setsearchAddress,
        phone,
        setphone,
        searchObject,
        setsearchObject,
        intNumber,
        setintNumber
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsFromStorage]
  );

  const rows = useMemo(() => {
    const { data } = allFacilities;
    return data?.length
      ? data.map(
        ({
          id,
          name,
          facilityOverallRating,
          integrationNumber,
          fireCompartments,
          address,
          messageChart,
          phoneNumber,
          enableForEdit
        }) => {
          let formattedAddress = "";
          if (address) {
            const { city, street, building, extension } = address;
            formattedAddress = [city, street, building, extension]
              .filter((item) => !!item)
              .join(", ");
          }
          return {
            id,
            integrationNumber,
            phone: phoneNumber,
            numberAndNameObjects: {
              name: `${name}`,
              action: () => handleOpenObjectDialogState(id),
            },
            facilityOverallRating,
            equipmentRegistry: {
              name: t("tableHeader:equipment"),
              action: () => navigate(PATHS.LIST_EQUIPMENT + `?objectId=${id}`),
            },
            fireCompartments: fireCompartments?.length
              ? fireCompartments.map(({ id, compartmentNumber, name }) => ({
                id,
                name: `${compartmentNumber || ""} ${name || ""}`,
                action: () => navigate(PATHS.GET_EDIT_FIRE_COMPARTMENT(id)),
              }))
              : [],
            address: formattedAddress,
            settings: enableForEdit ?
              <Tooltip title={"Настройка назначения задач"}>
                <Button
                  className="table-row-cell-link"
                  sx={{ textTransform: "none", whiteSpace: "nowrap" }}
                  onClick={() =>
                    handleOpenSettingsDialog(id, name)
                  }
                  startIcon={<SettingsOutlinedIcon sx={{ marginLeft: "10px", width: "30px", height: "30px" }} />}
                >

                </Button>
              </Tooltip> : <></>,

            edit: enableForEdit ? <EditButton handleClick={() => navigate(PATHS.GET_EDIT_OBJECT(id))} /> : <></>,
            tasks: getDataToChart({ jobChart: messageChart, action: `?facilityName=${id}` }),
          };
        }
      )
      : [];
    // eslint-disable-next-line
  }, [allFacilities]);

  const recordsCount = useMemo(
    () => allFacilities && allFacilities?.recordsCount,
    [allFacilities]
  );

  useEffect(() => {
    const city = searchAddress?.city ? `&AddressFilter.City=${searchAddress?.city}` : ""
    const street = searchAddress?.street ? `&AddressFilter.Street=${searchAddress?.street}` : ""
    const building = searchAddress?.building ? `&AddressFilter.Building=${searchAddress?.building}` : ""
    const address = city + street + building
    getFacilitiesTable({ pageNumber: 0, IntegrationNumber: intNumber, Name: searchObject, ShowObjectInFireAlarmsPerActiveSession: ShowObjectInFireAlarmsPerActiveSession ? ShowObjectInFireAlarmsPerActiveSession : "", Address: address });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, searchAddress, searchObject, ShowObjectInFireAlarmsPerActiveSession, intNumber]);

  useEffect(() => {
    const city = searchAddress?.city ? `&AddressFilter.City=${searchAddress?.city}` : ""
    const street = searchAddress?.street ? `&AddressFilter.Street=${searchAddress?.street}` : ""
    const building = searchAddress?.building ? `&AddressFilter.Building=${searchAddress?.building}` : ""
    const address = city + street + building
    getFacilitiesTable({ pageNumber: pageNumber, IntegrationNumber: intNumber, Name: searchObject, ShowObjectInFireAlarmsPerActiveSession: ShowObjectInFireAlarmsPerActiveSession ? ShowObjectInFireAlarmsPerActiveSession : "", Address: address });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    setIsErrorRequest(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  const handleOpenSelectColumnsModal = () => {
    setOpenSelectModal(true);
  };
  return (
    <Box className="legal-entity">
      <ModalSelectColumnsServer
        open={openSelectModal}
        setOpen={setOpenSelectModal}
        data={columnsFromStorage}
        refetch={() => getUserGrid({ gridSettingName: "ProtectionObjectList" })}
        nameStorage={"ProtectionObjectList"}
        config={getConfigFormModalSelect(gridcolumns?.columnSettings || [])}
      />
      {openObjectDialog && (
        <ObjectDialog
          open={openObjectDialog}
          onClose={handleOpenObjectDialogState}
          objectId={selectedObjectId}
        />
      )}
      {equipmentRegistryDialog && (
        <EquipmentRegistry
          open={equipmentRegistryDialog}
          onClose={handleEquipmentRegistryDialog}
          objectId={selectedObjectId}
        />
      )}
      <Box className="legal-entity-header" sx={{ display: "flex", placeContent: "space-between", alignItems: "center" }}>
        <Typography className="title-page">
          {t("title_page:list_objects")}
        </Typography>
        <Box>

          <Button
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            onClick={() => navigate(PATHS.OBJECTS_MAP)}
          >
            <Typography
              variant="body1"
              sx={{
                color: "var(--additionalGrey)",
                marginRight: "8px",
              }}
            >
              {t("title_page:objects_on_map")}
            </Typography>
            <MapOutlinedIcon
              sx={{
                color: "var(--additionalGrey)",
                height: "55px",
                width: "40px",
              }}
            />
          </Button>
        </Box>
      </Box>
      <Box sx={{ boxSizing: "border-box" }}>
        <RatingBox />
      </Box>
      <Box sx={{ textAlign: "end", paddingTop: "20px", paddingBottom: "20px" }}>
        <Button
          className="btn btn-outlined-primary magazine_events-select_container-btn"
          variant="outlined"
          onClick={handleOpenSelectColumnsModal}
          sx={{ height: "49px", marginRight: "0px" }}
        >
          {t("button:customize_fields_to_display")}
        </Button>
      </Box>
      <TableBasic
        columns={columns}
        rows={rows}
        handleClickCell={() => { }}
        withSearchField={false}
        pageNumber={pageNumber}
        changePageNumber={changePageNumber}
        isLoading={isLoading}
        count={recordsCount}
        minHeight={"50vh"}
        maxHeight={"50vh"}
      />
    </Box>
  );
};

export default ObjectsList;
