import { Avatar, Box, IconButton, Menu, Typography } from "@mui/material";
import * as React from "react";
import { useAppSelector } from "../../../hooks/redux";
import { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import ProfileItemView from "../../components/ProfileItemView";
import { useTranslation } from "react-i18next";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../constants";
import { persistor } from "../../../redux/store";
import { useLazyGetProfileInfoQuery, useCloseSessionMutation } from "../../../middleware/usersApi";
import { useAction } from "../../../hooks/actions";
import CircularProgress from "@mui/material/CircularProgress";

const ProfileDropdown = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    token,
    sessionId,
    info: { firstName, lastName, avatarLink },
  } = useAppSelector((state) => state.users);
  const [anchorEl, setAnchorEl] = useState(null);
  const { setAuthStep } = useAction();

  const [getProfileInfo, { isError, isLoading }] = useLazyGetProfileInfoQuery();
  const [closeSession, { error: errorcloseSession, isSuccess: isSuccesscloseSession }] =
    useCloseSessionMutation();

  useEffect(() => {
    const requiredNecessaryValues = [firstName, lastName];
    const definedRequiredNecessaryValues = requiredNecessaryValues.filter(
      (value) => value
    );
    if (
      requiredNecessaryValues.length !== definedRequiredNecessaryValues.length
    ) {
      getProfileInfo(token);
    }
    // eslint-disable-next-line
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    closeSession({ token,
      data: {
        sessionId: sessionId 
      }, }).then(() => {
       persistor.purge().then(() => {
        setAuthStep(0);
        navigate(PATHS.LOGIN_ROUTE);
      });
      localStorage.clear();
    })

  };

  if (isError) {
    return null;
  }

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Box>
      <IconButton aria-expanded={anchorEl} onClick={handleClick}>
        <Avatar
          alt="avatar"
          src={avatarLink}
          sx={{ backgroundColor: "var(--main)" }}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        sx={{
          "& .MuiMenu-list": {
            p: "10px",
          },
        }}
      >
        <MenuItem
          sx={{ display: "block", p: "10px" }}
          onClick={() => navigate(PATHS.PROFILE_ROUTE)}
        >
          <ProfileItemView />
          <Typography sx={{ mt: "10px" }}>{t("label:profile")}</Typography>
        </MenuItem>
        <MenuItem sx={{ p: "10px" }} onClick={handleLogout}>
          <LogoutIcon />
          <Typography sx={{ ml: "5px" }}>{t("label:logout")}</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ProfileDropdown;
