import React from "react";

function polarToCartesian(radius, angleInRadians) {
  const x = radius * Math.cos(angleInRadians);
  const y = radius * Math.sin(angleInRadians);
  return { x, y };
}

const emptyChartParams = { value: 0, color: "#e2e8f0",morethanOne:false};

const DonutChart = ({
  id = "",
  data,
  width,
  height,
  radius,
  thickness = radius / 2,
  isText = false,
  fontSize = "10px",
  style = { pointerEvents: "all" }
}) => {


  let fire_messages_amount = "";
  let false_fire_messages_amount = "";
  let fillColor = emptyChartParams.color;
  data && data.length>0 && data?.map(({ value, color,name }, i) =>{
    if (name == "fire_messages_amount" && value >0) fire_messages_amount = color;
    if (name == "false_fire_messages_amount" && value >0) false_fire_messages_amount = color;
  }
  )
  if(fire_messages_amount =="" && !false_fire_messages_amount =="")
    fillColor = emptyChartParams.color
  else if (fire_messages_amount != "")
    fillColor = fire_messages_amount
  else if (fire_messages_amount =="" && false_fire_messages_amount !="")
    fillColor = false_fire_messages_amount
  const total = data?.filter((item) => item.name)?.reduce((sum, d) => sum + d.value, 0);
  
  let startAngle = 0;
  let endAngle = 0;
  const drawSectorChart = ({ value, color,morethanOne }, i) => {
    let offset = 0
  
    if(value>0 && morethanOne) offset = 0.1
    const percentage = total ? (value / total) : 1;
    endAngle = startAngle + percentage * 2 * Math.PI-offset;
    const innerRadius = radius - thickness;
    const outerStart = polarToCartesian(radius, startAngle);
    const outerEnd = polarToCartesian(radius, endAngle);
    const innerStart = polarToCartesian(innerRadius, startAngle);
    const innerEnd = polarToCartesian(innerRadius, endAngle);
    const largeArcFlag = percentage > 0.5 ? 1 : 0;
    const pathData = [
      `M ${outerStart.x} ${outerStart.y}`,
      `A ${radius} ${radius} 0 ${largeArcFlag} 1 ${outerEnd.x} ${outerEnd.y}`,
      `L ${innerEnd.x} ${innerEnd.y}`,
      `A ${innerRadius} ${innerRadius} 0 ${largeArcFlag} 0 ${innerStart.x} ${innerStart.y}`,
      `Z`,
    ].join(" ");
    
    startAngle = endAngle+offset;
    return <path d={pathData} fill={color} key={i || 0} />;
  };
  
  const isMoreThanOne = total
  ?  data.filter(obj => obj.value > 0).length >=2 ? true : false
  : false;
  const dddata = data.filter(obj => obj.value > 0)
  console.log("isMoreThanOne",data.filter(obj => obj.value > 0))

  const arcs = total
    ? data?.filter((item) => item.name).map(({ value, color }, i) => drawSectorChart({ value, color,morethanOne:isMoreThanOne }, i))
    : drawSectorChart(emptyChartParams);

  const textX = 0;
  const textY = 0;
  const textStyle = {
    fontSize,
    fontFamily:"var(--IBM)",
    textAnchor: "middle",
    dominantBaseline: "central",
    fontWeight: 500,
    fill: fillColor,
  };

  return (
    <svg id={id} width={width} height={height} style={style}>
      <circle cx={width / 2} cy={height / 2} r={height / 2} fill="white" />
      <g transform={`translate(${width / 2}, ${height / 2})`}>
        {arcs}
        {isText && (
          <text x={textX} y={textY} style={textStyle}>
            {total}
          </text>
        )}
      </g>
    </svg>
  );
};

export default DonutChart;
