
import ObjectsList from "./pages/ObjectsList";
import ObjectsOnMap from "./pages/ObjectsOnMap";
import AuthSteps from "./pages/LogIn/AuthSteps";
import React from "react";
import ResetPassword from "./pages/LogIn/ResetPassword";
import SetPassword from "./pages/LogIn/SetPassword";
import LogIn from "./pages/LogIn";
import Profile from "./pages/Profile";
import { MagazineEvents } from "./pages/MagazineEvents";
import { PATHS } from "../constants";
import { MessageCodePage } from "./pages/MessageTemplatePage/MessageCodePage";
import Controllers from "./pages/Controllers";
import AddController from "./pages/Controllers/AddController";
import { EmployeeList } from "./pages/EmployeeList";

import { AddEmployee } from "./pages/EmployeeList/AddEmployee";
import { EditEmployee } from "./pages/EmployeeList/EditEmployee";
export const TASKS = "/tasks";
export const ORGANIZATIONS = "/organizations";
export const RESPONSIBLE = "/responsible";
export const OBJECTS = "/objects";
export const MAGAZINES = "/magazines";
export const ANALYTICS = "/analytics";
export const FIRE_COMPARTMENTS = "/fire_compartments";
export const EQUIPMENT = "/equipment";
export const TIMETABLE = "time_table";
export const DIGITIZATION = "/digitization";

export const excludeInBreadcrumb = [
  "/edit_organization",
  "/edit_object",
  "/edit_employee",
  "/edit_r_organization",
  "/completed_jobs_amount",
  "/needs_attention_jobs_amount",
  "/overdued_jobs_amount",
  "/all_completed",
  
];

export const rootPaths = [
  ORGANIZATIONS,
  OBJECTS,
  MAGAZINES,
  RESPONSIBLE,
  EQUIPMENT,
  DIGITIZATION,
  TASKS,
  FIRE_COMPARTMENTS,
];

export const AppRoutes = [
  {
    path: PATHS.LIST_EMPLOYEES,
    component: <EmployeeList />,
    exact: true,
    name: "employees",
    requiresAuth: true,
    permission: "users",
  },
  {
    path: PATHS.EDIT_EMPLOYEE,
    component: <EditEmployee />,
    exact: true,
    name: "edit_employee",
    requiresAuth: true,
    permission: "users",
  },
  {
    path: PATHS.ADD_EMPLOYEE,
    component: <AddEmployee />,
    exact: true,
    name: "add_employees",
    requiresAuth: true,
    permission: "users",
  },
  {
    path: PATHS.LIST_OBJECTS,
    component: <ObjectsList />,
    exact: true,
    name: "list_objects",
    requiresAuth: true,
    permission: "facilities",
  },
  {
    path: PATHS.OBJECTS_MAP,
    component: <ObjectsOnMap />,
    exact: true,
    name: "objects_on_map",
    requiresAuth: true,
    permission: "facilities",
  },
  {
    path: PATHS.MESSAGE_TEMPLATE,
    component: <MessageCodePage />,
    exact: true,
    name: "message_template",
    requiresAuth: true,
    permission: "equipments",
  },
  {
    path: PATHS.PROFILE_ROUTE,
    component: <Profile />,
    exact: true,
    name: "profile",
    requiresAuth: true,
    permission: "profile",
  },
  {
    path: PATHS.MAGAZINES_EVENTS,
    component: <MagazineEvents />,
    exact: true,
    name: "magazines_events",
    requiresAuth: true,
    permission: "logs",
  },
  {
    path: PATHS.CONTROLLERS,
    component: <Controllers />,
    exact: true,
    name: "controllers",
    requiresAuth: true,
    permission: "control-console",
  },
  {
    path: PATHS.ADD_CONTROLLER,
    component: <AddController />,
    exact: true,
    name: "add_controller",
    requiresAuth: true,
    permission: "control-console",
  },
  {
    path: PATHS.CONTROLLER_MESSAGE_TEMPLATE,
    component: <MessageCodePage />,
    exact: true,
    name: "controller_message_template",
    requiresAuth: true,
    permission: "control-console",
  },
];

export const NonAuthRoutes = [
  {
    path: PATHS.LOGIN_ROUTE,
    component: (
      <LogIn>
        <AuthSteps />
      </LogIn>
    ),
    exact: true,
    name: "login",
    requiresAuth: false,
  },
  {
    path: PATHS.RESET_PASSWORD_ROUTE,
    component: (
      <LogIn>
        <ResetPassword />
      </LogIn>
    ),
    exact: true,
    name: "reset_password",
    requiresAuth: false,
  },
  {
    path: PATHS.SET_PASSWORD_ROUTE,
    component: (
      <LogIn>
        <SetPassword />
      </LogIn>
    ),
    exact: true,
    name: "set_password",
    requiresAuth: false,
  },
];
