import { createSlice } from "@reduxjs/toolkit";
import { analyticsApi } from "../../middleware/analyticsApi";

const initialState = {
  analyticData: {},
};

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      analyticsApi.endpoints.getSystemOverallAnalytics.matchFulfilled,
      (state, { payload }) => {
        state.analyticData = payload;
      }
    );
  },
});

export const analyticsActions = analyticsSlice.actions;
export const analyticsReducer = analyticsSlice.reducer;